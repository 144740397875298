import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'
import can from '../../services/rbac'

import GameContext from '../../GameContext'
import { StyledButtonLink } from '../Styled'

import Item from './Item'

import './style.css'

const Items = ({
  canSell,
  classes,
  fullVariants,
  home,
  hot,
  isCatalog,
  items,
  loading,
  receiveItem,
  row,
  selectAll,
  setWflItems,
  size,
  styles,
  suggest,
  wflItems,
  wishlists,
}) => {
  const { game, routePrefix } = useContext(GameContext)
  const theme = useContext(ThemeContext)

  return (
    <>
      <div className='row'>
        {items.map((item) => {
          return (
            <Item
              classes={classes}
              key={item.id}
              item={item}
              fullVariants={fullVariants}
              home={home}
              row={row}
              hot={hot}
              wishlists={wishlists}
              receiveItem={receiveItem}
              isCatalog={isCatalog}
              canSell={canSell}
              selectAll={selectAll}
              setWflItems={setWflItems}
              styles={styles}
              wflItems={wflItems}
            />
          )
        })}
        {loading &&
          Array(size || 50)
            .fill()
            .map((index, idx) => (
              <div
                key={idx}
                className={`${
                  home
                    ? 'col-xs-6 col-sm-6 col-md-6'
                    : 'col-xs-6 col-sm-4 col-md-3'
                } item`}
              >
                <SkeletonTheme
                  color={theme.bodyAlt}
                  highlightColor={theme.skeletonLoading}
                >
                  <div className='item-skeleton'>
                    <div className='item-skeleton-content'>
                      <Skeleton
                        key={index}
                        style={{ borderRadius: '20px' }}
                        height='100%'
                      />
                    </div>
                  </div>
                </SkeletonTheme>
              </div>
            ))}
      </div>
      {!loading && items.length === 0 && (
        <>
          <div className='no-items'>
            {game.icons.noresults && (
              <img
                src={game.icons.noresults}
                alt='resetti'
                className='resetti'
              />
            )}
            <Trans i18nKey='noResults' />
          </div>
          {suggest && can('DATA:SUGGEST_ITEMS') && (
            <div className='search-suggest'>
              Can't find the item you're looking for?{' '}
              <StyledButtonLink to={`${routePrefix}/product/suggest`}>
                Suggest a New Item
              </StyledButtonLink>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Items
