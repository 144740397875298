import React, { useState, useEffect, useContext } from 'react'
import { Trans } from 'react-i18next'

import { UserContext } from '../../contexts'
import http from '../../services/http'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'
import { StyledButtonLink } from '../../components/Styled'

import LoginForm from './LoginForm'

const VerifyEmail = ({ match, history }) => {
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)
  const [verifyMsg, setVerifyMsg] = useState('')
  const [currAlert, setCurrAlert] = useState('')
  const { user } = useContext(UserContext)

  useEffect(() => {
    const activationHash = match.params.activation_hash
    if (activationHash) {
      http
        .post('/activate', { activation_hash: activationHash, type: 'verify' })
        .then((res) => {
          if (res.error) {
            setVerified(false)
            setLoading(false)
            setCurrAlert(res.error)
            return
          }
          localStorage.removeItem('fromRef')
          setVerified(true)
          setLoading(false)
          setVerifyMsg(res.msg)
        })
    }
  }, [])

  if (loading) return <Loading />

  return (
    <div className='login'>
      {verified ? (
        <div className='container'>
          {user ? (
            <>
              <h2>
                <Trans i18nKey='thankVerifyLoggedIn' />
              </h2>
              <div style={{ display: 'flex', marginTop: 16 }}>
                <StyledButtonLink to='/'>Start Trading</StyledButtonLink>
              </div>
            </>
          ) : (
            <>
              {verifyMsg === '' || verifyMsg == null ? (
                <div>
                  <Trans i18nKey='thankVerify' />:
                </div>
              ) : (
                <div>{verifyMsg}</div>
              )}
              <LoginForm history={history} />
            </>
          )}
        </div>
      ) : (
        <div>
          <Trans i18nKey='failedVerify' />
        </div>
      )}
      {currAlert !== '' && (
        <Alert onClick={setCurrAlert('')} alert={currAlert} />
      )}
    </div>
  )
}

export default VerifyEmail
