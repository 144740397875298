import React, { useState, useContext } from 'react'

import { TagFilter } from './index'
import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'

const lang = window.localStorage.getItem('defaultLanguage')
const DefaultTagFilter = ({
  match,
  onFilter,
  query,
  tagCategories,
  tagCategory,
  updateQuery,
  activeFilters,
  setActiveFilters,
  active,
  wfl,
}) => {
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)
  let queryFilter = query.tags
    ? query.tags.concat([query.type])
    : query.type
    ? [query.type]
    : []
  if (tagCategory === 'Categories' && match?.params?.type !== undefined)
    queryFilter.push(match.params.type)
  const [selectedTags, setSelectedTags] = useState(queryFilter)

  const tags = tagCategories[tagCategory].sort(
    (a, b) => a.format?.order - b.format?.order
  )

  return (
    <div className='tag-filters'>
      {tags.map((tag) => {
        const { format } = tag
        let tagName = tag.tag
        if (lang && tag[lang]) tagName = tag[lang]
        return (
          <TagFilter
            key={tag.tag}
            className='tag-filter'
            onClick={() => {
              let newSelectedTags = []
              if (selectedTags.includes(tag.tag)) {
                newSelectedTags = selectedTags.filter((t) => t !== tag.tag)
                if (activeFilters) {
                  const newFilters = activeFilters.filter(
                    (f) =>
                      f.id !== tag.tag_id &&
                      f.label !== tag.tag &&
                      f.id !== tag.id
                  )
                  setActiveFilters(newFilters)
                }
              } else {
                newSelectedTags = selectedTags.filter(
                  (t) => !tags.map((ta) => ta.tag).includes(t)
                )
                newSelectedTags.push(tag.tag)
                if (game.has('LISTINGS:STATS')) {
                  window.dataLayer.push({
                    event: 'tagFilter',
                    eventProps: {
                      category: `${tag.category}`,
                      action: `${tag.tag}`,
                    },
                    userId: user ? user.id : undefined,
                    email: user ? user.email : undefined,
                  })
                }
                if (activeFilters) {
                  let newActiveFilters =
                    active === undefined ? activeFilters : []
                  if (active !== undefined) {
                    newActiveFilters = activeFilters.filter(
                      (f) =>
                        f.label !== active.tag &&
                        f.id !== active.tag_id &&
                        f.type !== 'tag'
                    )
                  }
                  newActiveFilters.push({
                    label: tag.tag,
                    id: tag.tag_id,
                    type: 'tag',
                  })
                  setActiveFilters(newActiveFilters)
                }
                window.dataLayer.push({
                  event: 'filters',
                  eventProps: {
                    category: 'Filters',
                    action: `Apply A Filter - ${
                      activeFilters ? 'Homepage' : 'General'
                    }`,
                  },
                })
              }
              setSelectedTags(newSelectedTags)
              const payload = { page: undefined }
              if (wfl) {
                const wflFilters = wfl.map((t) => t.tag)
                if (tagCategory === 'Types') {
                  let type = wfl
                    .filter((filter) => newSelectedTags.includes(filter.tag))
                    .map((f) => f.tag)[0]
                  if (type) payload.type = type
                  payload.tags = newSelectedTags.filter((t) => t !== type)
                }
                payload.tags = newSelectedTags.filter(
                  (t) => !wflFilters.includes(t)
                )
                payload.type = newSelectedTags.filter((t) =>
                  wflFilters.includes(t)
                )[0]
              } else {
                payload.tags = newSelectedTags
              }
              if (tag.category === 'Categories') {
                payload.categories = selectedTags.includes(tag.tag)
                  ? {}
                  : { option: tag.tag }
              }
              updateQuery(payload)
              if (onFilter) onFilter()
            }}
            active={selectedTags.includes(tag.tag)}
          >
            {format?.img && (
              <img src={format.img} alt={tagName} className='tag-img' />
            )}
            {format?.hideLabel ? '' : tagName.replace('_', ' ')}
          </TagFilter>
        )
      })}
    </div>
  )
}

export default DefaultTagFilter
