import React from 'react'
import { components } from 'react-select'

const ItemOption = (props) => {
  const { data, customStyles } = props
  const img_url = data.img_url
  return (
    <components.Option {...props}>
      <div
        className='item-with-image'
        style={
          customStyles?.fontSize && {
            fontSize: customStyles.fontSize,
          }
        }
      >
        {img_url && (
          <img
            className='image-icon'
            src={img_url}
            alt='brand-img'
            style={
              customStyles?.imgSize && {
                width: customStyles.imgSize,
                height: customStyles.imgSize,
              }
            }
          />
        )}
        {' ' + data.label}
      </div>
    </components.Option>
  )
}

export default ItemOption
