import React from 'react'
import { FaBan } from 'react-icons/fa'
import { RiHeartAddLine } from 'react-icons/ri'
import styled from 'styled-components'
import lozad from 'lozad'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'
import { toHumanNumber } from '../../services/validate'
import can from '../../services/rbac'

import { ThemeDiv, StyledLink } from '../Styled'
import Tooltip from '../Tooltip'

import ItemIcon from './ItemIcon'
import AddToCatalog from './AddToCatalog'
import AddToWishlist from './AddToWishlist'
import VariantSelect from './VariantSelect'
import ItemProps from './ItemProps'
import WFLProperties from '../../pages/WinFairLose/WFLProperties'

const ItemContainer = styled(ThemeDiv)`
  border-radius: ${({ theme }) => theme.itemRadius || '20px'};

  &.active {
    border: ${({ theme }) => `solid ${theme.btnBackground}`};
  }
`

const ItemName = styled.div`
  color: ${({ theme }) => theme.itemName};
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
`

const diyVariant = {
  img: 'https://cdn.nookazon.com/nookazon/icons/recipe.png',
  active: true,
  name: 'DIY',
}

const allVariant = {
  img: 'https://cdn.nookazon.com/AllVariants.png',
  active: true,
  name: 'All Variants',
}

const anyVariant = {
  img: 'https://cdn.nookazon.com/nookazon/icons/AnyVariant.png',
  active: true,
  name: 'Any Variant',
}

const HotTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  font-weight: bold;
  padding: 0 10px;
  color: white;
  border-bottom-right-radius: 10px;
  border-top-left-radius: ${({ theme }) => theme.itemRadius};
`

// const motionVariants = {
//   zoomIn: { scale: 1.1, opacity: 0 },
//   stop: { scale: 1, opacity: 1 },
// }

class Item extends React.Component {
  state = {
    open: false,
    active: false,
    variant: {},
  }
  static contextType = GameContext

  componentDidMount = () => {
    let { item, wflItems, selectAll } = this.props
    const { game } = this.context
    const observer = lozad()
    observer.observe()

    if (wflItems && wflItems.find((i) => i.id === item.id) !== undefined) {
      this.setState({ active: true })
    }

    if (item.variants && item.diy === false) {
      this.setState({ variant: item.variants[0] })
    }

    if (item.diy === true) {
      if (item.variants) {
        item.variants.push(diyVariant)
        this.setState({ variant: item.variants[0] })
      } else {
        item.variants = [diyVariant]
        this.setState({ variant: {} })
      }
    }
    if (selectAll && item.variants && item.variants.length > 1)
      item.variants.push(allVariant)

    if (
      game.has('ITEMS:ANY_VARIANT') &&
      item.variants &&
      item.variants.length > 1
    )
      item.variants.push(anyVariant)
  }

  setActive = (activeBool) => {
    this.setState({ active: activeBool })
  }

  render = () => {
    const {
      canSell,
      classes,
      fullVariants,
      home,
      hot,
      iconSize,
      isCatalog,
      item,
      noGrid,
      noTradeLabel,
      properties,
      receiveItem,
      row,
      setWflItems,
      styles,
      wflItems,
      wishlists,
    } = this.props
    const { open, variant, active } = this.state
    const { game, routePrefix } = this.context
    const user = getUser()
    const isOverwolf = localStorage.getItem('overwolf')
    const seeAvgValueRH = can('ITEMS:COMMUNITY_VALUE')
    const itemPrice =
      Object.keys(variant).length > 0
        ? item?.prices?.find((p) => p.variant_id === variant.id)
        : item.prices?.length > 0
        ? item.prices[0]
        : null

    return (
      <div
        key={item.id + item.variant_id}
        className={`${
          noGrid
            ? ''
            : home
            ? row
              ? 'col-xs-6 col-sm-3 col-md-3'
              : 'col-xs-6 col-sm-6 col-md-6'
            : classes?.itemContainer
            ? classes?.itemContainer
            : 'col-xs-6 col-sm-4 col-md-3'
        } fade`}
      >
        <div
          className='item'
          onClick={() => {
            if (setWflItems && !item.variants) {
              setWflItems(item)
              this.setState({ active: !active })
            }
          }}
          style={{
            cursor: setWflItems ? 'pointer' : '',
          }}
        >
          <ItemContainer
            className={`item-img-container ${active ? 'active' : ''}`}
          >
            <div className='item-container-img-icon'>
              {setWflItems ? (
                <div className='wfl-item-img'>
                  <ItemIcon
                    item={item}
                    variant={variant}
                    size={setWflItems ? 96 : iconSize || 128}
                    lozad
                  />
                </div>
              ) : (
                <StyledLink
                  to={{
                    pathname: `${routePrefix}/product/${item.id}${
                      game.has('ITEMS:LATEST_CATALOG') ? '/catalog' : ''
                    }`,
                    itemInfo: item,
                  }}
                  className='item-img'
                  style={{ backgroundSize: 'cover' }}
                >
                  <ItemIcon
                    item={item}
                    variant={variant}
                    size={iconSize || 128}
                    lozad
                  />
                </StyledLink>
              )}
              <ItemName>{item.name}</ItemName>
              {!hot &&
                !game.has('TRADING:DISABLED') &&
                !noTradeLabel &&
                (!item.active ||
                  (Object.keys(variant).length > 0 && !variant.active)) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaBan />
                    &nbsp;
                    <Trans i18nKey='itemNonTrade' />
                  </div>
                )}
              <div className='desktop-variant-name'>
                {variant.name ? variant.name : <>&nbsp;</>}
              </div>
              {setWflItems && (
                <WFLProperties
                  item={item}
                  variant={variant}
                  wflItems={wflItems}
                  setWflItems={setWflItems}
                  setActive={this.setActive}
                />
              )}
              {item.active && item.prices && !setWflItems && (
                <div className='item-currencies'>
                  {!seeAvgValueRH && game.schema === 'royalehigh'
                    ? null
                    : game.hasCurrencies() &&
                      game.currencies.map((currCurrency) => {
                        let currAvg = 0
                        let count = 0
                        item.prices.forEach((p) => {
                          if (p.variant_id !== undefined) {
                            if (
                              p.variant_id ===
                              (variant ? variant.id || null : null)
                            ) {
                              currAvg += p[currCurrency.priceKey || 'avg']
                              count++
                            }
                          } else {
                            currAvg += p[currCurrency.priceKey || 'avg']
                            count++
                          }
                        })
                        if (!currAvg) return null
                        const avg = currAvg / count
                        return (
                          <div
                            className='listing-bells'
                            key={currCurrency.priceKey || 'avg'}
                          >
                            {currCurrency.img ? (
                              <img
                                draggable={!isOverwolf}
                                src={currCurrency.img}
                                alt={currCurrency.name}
                                className='bells-icon'
                              />
                            ) : (
                              `${currCurrency.name}: `
                            )}
                            {!currCurrency.fixed
                              ? currCurrency.humanize
                                ? toHumanNumber(avg * (currCurrency.base || 1))
                                : Math.ceil(
                                    avg * (currCurrency.base || 1)
                                  ).toLocaleString()
                              : avg % 1 !== 0
                              ? avg.toFixed(Math.min(4, (avg + '').length - 2))
                              : avg.toLocaleString()}
                          </div>
                        )
                      })}
                  {itemPrice && itemPrice.user_value && (
                    <div className='listing-bells'>
                      <img
                        src='https://cdn.nookazon.com/traderie/icons/icon20x20.png'
                        alt='community value'
                        className='bells-icon'
                      />
                      {itemPrice.user_value.toLocaleString()}
                    </div>
                  )}
                </div>
              )}
              {properties && <ItemProps item={item} />}
            </div>
            {hot && <HotTag>HOT</HotTag>}
            {user && receiveItem && (isCatalog ? canSell : true) && (
              <div className='item-catalog'>
                <Tooltip
                  text={
                    <Trans
                      i18nKey='addToCatalog'
                      values={{ catalog: game.getCatalogKey() }}
                    />
                  }
                  top='80%'
                >
                  <AddToCatalog
                    item={item}
                    variant={variant}
                    icon
                    receiveItem={receiveItem}
                  />
                </Tooltip>
              </div>
            )}
            {user && wishlists && (
              <ThemeDiv
                className='item-wishlist'
                onClick={() => {
                  this.setState({ open: !open })
                }}
              >
                <Tooltip text={<Trans i18nKey='addToWishlist' />} top='80%'>
                  <RiHeartAddLine />
                </Tooltip>
              </ThemeDiv>
            )}
          </ItemContainer>
          {item.variants && (
            <VariantSelect
              catalog={item.catalog}
              full={fullVariants}
              onChange={(vari, remove) => {
                const activeItem = wflItems?.find((i) => i.id === item.id)
                const activeVar = wflItems?.find(
                  (i) => i.variant?.id === vari?.id
                )
                const activeOtherVar = wflItems?.find(
                  (i) =>
                    (i.variant && i.variant.id !== vari?.id) ||
                    (!i.variant && i.id === item.id)
                )
                if (wflItems) {
                  if (activeItem === undefined || activeVar === undefined) {
                    this.setState({ active: true })
                  }
                  if (activeVar !== undefined && activeOtherVar !== undefined) {
                    this.setState({ active: true })
                  }
                  if (activeVar !== undefined && activeOtherVar === undefined) {
                    this.setState({ active: false })
                  }
                  setWflItems(item, vari)
                }

                if (remove) {
                  if (activeOtherVar && activeOtherVar.variant) {
                    this.setState({ variant: activeOtherVar.variant })
                  } else {
                    this.setState({ variant: {} })
                  }
                } else {
                  this.setState({ variant: vari })
                }
              }}
              scroll
              show={3}
              styles={styles?.variants}
              variant={variant}
              variants={item.variants}
              wflItems={wflItems}
            />
          )}
          {wishlists && (
            <div
              style={{
                maxHeight: open ? 500 : 0,
                transition: '.2s',
                overflow: open ? '' : 'hidden',
              }}
            >
              <AddToWishlist
                wishlists={wishlists}
                item={item}
                variant={variant}
                onAdded={() => {
                  this.setState({ open: false })
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Item
