import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { IoIosFlag, IoIosShareAlt } from 'react-icons/io'
import { MdLocalOffer } from 'react-icons/md'
import { Trans, useTranslation } from 'react-i18next'
import qs from 'qs'
import { itemHas, getModeLabel } from 'item-mode'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import SocketContext from '../../contexts/SocketContext'
import { getUser } from '../../services/users'
import { isNumber, upperCaseEachWord } from '../../services/validate'
import can from '../../services/rbac'
import http from '../../services/http'
import {
  getCurrentTutorial,
  hasFinishedTutorial,
} from '../../services/tutorial'

import { getTimeLeft } from '../../components/Listings/ListingItem'
import { StyledButtonLink, StyledLink } from '../../components/Styled'
import AdSlot from '../../components/AdSlot'
import CartDrawer from '../../components/Offers/CartDrawer'
import CopyButton from '../../components/inputs/CopyButton'
import CreateListing from '../../components/Listings/CreateListing'
import GoBack from '../../components/GoBack'
import Helmet from '../../components/Helmet'
import { default as ListingData } from '../../components/Listings/Listing'
import Listings from '../../components/Listings/Listings'
import ListingSkeleton from './ListingSkeleton'
import MakeOffer from '../../components/Offers/MakeOffer'
import MakeOfferCatalog from '../../components/Offers/MakeOfferCatalog'
import Offers from '../../components/Offers'
import Report from '../../components/Report'
import Tooltip from '../../components/Tooltip'
import Alert from '../../components/Alert'
import Tutorial from '../../components/Tutorial'

import ListingInfo from './ListingInfo'
import ModActions from './ModActions'
import OfferListing from './OfferListing'

import './style.css'

const StyledOffersTab = styled.div`
  background-color: ${({ theme }) => theme.productImgBackground};
  display: inline-block;
  font-weight: bold;
  margin: ${({ theme }) => theme.offersTabMargin};
  margin-bottom: 10px;
  padding: 10px 15px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.btnBackground};
    height: 6px;
    border-radius: 10px;
  }
`

// const isApp = window.localStorage.getItem('app')
let mobileView = window.innerWidth < 600

const Listing = ({ match, history, location }) => {
  const query = qs.parse(location.search.substring(1))
  const { game, routePrefix } = useContext(GameContext)
  const socket = useContext(SocketContext)
  const isCompactView = game.has('LISTINGS:COMPACT')
  const isMultiListing = game.has('LISTINGS:MULTI')
  const noListingOffer = game.has('OFFERS:NO_LISTING')

  const [loading, setLoading] = useState(true)
  const [listing, setListing] = useState({})
  // (this.props.location.state && this.props.location.state.listingInfo) ||
  // {},

  const [timeLeft, setTimeLeft] = useState(0)
  const [activeTimer, setActiveTimer] = useState(true)
  const [canOffer, setCanOffer] = useState(query.offering || false)
  const [offering, setOffering] = useState(
    query.offering || location?.state?.offering || false
  )
  const [viewAllOffers, setViewAllOffers] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [reporting, setReporting] = useState(false)
  const [editing, setEditing] = useState(false)
  const [currAlert, setAlert] = useState('')
  const [isTourRunning, setIsTourRunning] = useState(!hasFinishedTutorial())
  const [currentTutorial, setCurrentTutorial] = useState('')

  const currUser = getUser() || {}
  const { item = {} } = listing

  const { params } = match
  const isAuction = query.hasOwnProperty('auction')
  const isSeller = currUser?.id === listing?.seller?.id + ''

  const scrollRef = useRef()
  const topRef = useRef()

  const { t } = useTranslation()

  const getListing = useCallback(() => {
    const qsParams = { id: params.id, completed: 'all' }
    if (isAuction) qsParams.auction = 'true'
    const isLookingFor = query.lookingFor
    if (isLookingFor) qsParams.buying = ''
    if (game.has('LISTINGS:ITEM_TAGS')) qsParams.itemTags = true

    setLoading(true)

    if (isNumber(qsParams.id)) {
      return http.get(`/listings`, qsParams).then((res) => {
        if (res.error) {
          setAlert(res.error)
          return
        }
        if (!res.listings[0]) {
          setAlert(t('sellerOfflineOrNoListing'))
          return
        }

        const newListing = res.listings[0]
        setListing(newListing)
        setLoading(false)
        return newListing
        // isApp && window.scrollTo(0, 0)
      })
    } else {
      setAlert(t('invalidListing'))
      return
    }
  }, [history, isAuction, params.id, query.lookingFor])

  useEffect(() => {
    const tutorial = getCurrentTutorial()
    if (tutorial) {
      setCurrentTutorial(tutorial)
    }
  }, [setCurrentTutorial])

  useEffect(() => {
    if (scrollRef.current && scrollRef.current.scrollIntoView)
      scrollRef.current.scrollIntoView()

    // const { location } = this.props

    // if (location.state && location.state.listingInfo) {
    //   if (
    //     location.state.listingInfo.prices === null &&
    //     location.state.listingInfo.make_offer === false
    //   ) {
    //     this.getListing()
    //   } else {
    //     this.setState({ loading: false })
    //   }
    // } else {
    //   this.getListing()
    // }
  }, [params.id])

  useEffect(() => {
    getListing().then((newListing) => {
      if (newListing?.item && itemHas(newListing.item.mode, 'LISTINGS:LIVE')) {
        socket.emit('group_join', params.id)
      }
    })
  }, [socket, params.id, getListing])

  // Auction timer
  useEffect(() => {
    let auctionTimer = null
    if (listing.end_time) {
      let newTimeLeft = ''
      if (
        DateTime.fromISO(listing.end_time).diffNow().as('milliseconds') > 0 &&
        activeTimer
      ) {
        newTimeLeft = getTimeLeft(listing.end_time)
        auctionTimer = setInterval(() => {
          const timeLeft = getTimeLeft(listing.end_time)
          if (timeLeft === 'AUCTION OVER') {
            setActiveTimer(false)
            getListing()
          } else {
            setTimeLeft(timeLeft)
          }
        }, 1000)
      } else {
        setActiveTimer(false)
        newTimeLeft = 'AUCTION OVER'
      }
      setTimeLeft(newTimeLeft)
    }

    return () => clearInterval(auctionTimer)
  }, [listing.end_time, activeTimer, getListing])

  let offerTutorialSteps = []
  if (!sessionStorage.getItem('completedOffer')) {
    offerTutorialSteps = [
      {
        target: '.listing-page-price',
        content: `This is what the person wants for this trade.${
          listing && listing.make_offer
            ? ' This means the person is willing to take any offer.'
            : ''
        }`,
        disableBeacon: true,
        spotlightClicks: true,
      },
      {
        target: '.add-listing-container',
        content: `${
          listing && listing.accept_listing_price
            ? 'Click here to buy the item for the price listed above.'
            : 'Click here to make an offer on this item.'
        }`,
        disableBeacon: true,
        spotlightClicks: true,
      },
    ]
  }

  let listingTutorialSteps = [
    {
      target: '#listing-offers',
      content: `This is where you'll see all of the offers on your listing. Once you receive an offer you'll get a notification!`,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#listing-offers',
      content: `This is the end of the Listings tutorial. Happy Trading!`,
      disableBeacon: true,
      placement: 'center',
    },
  ]

  const tutorialSteps =
    currentTutorial && currentTutorial === 'Offer Tutorial'
      ? offerTutorialSteps
      : listingTutorialSteps

  const updateListing = (field, value) => {
    return http
      .put(`/listings/update`, { [field]: value, listing: listing.id })
      .then((res) => {
        if (res.error) return res.error
        getListing()
      })
  }

  const removeAlert = () => {
    setAlert('')
    return history.push(`${routePrefix}`)
  }

  if (loading)
    return (
      <div ref={scrollRef}>
        <ListingSkeleton />
        {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
      </div>
    )

  if (editing) {
    let currVariant
    if (listing.variant_id) {
      currVariant.id = listing.variant_id
      currVariant.name = item.variant_name
      currVariant.img = item.variant_img
    }
    item.img_url = item.img
    item.label = item.name

    return (
      <div className='product'>
        <div className='container'>
          <CreateListing
            header='editListing'
            listing={listing}
            item={item}
            variant={currVariant}
            history={history}
            editing
            close={() => {
              setEditing(false)
              getListing()
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='listing'>
        <Helmet data={{ item: upperCaseEachWord(item.name) || '' }} />
        <div className='container'>
          <span className='listing-content'>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <GoBack history={history} page={'listings'} />
                <CopyButton
                  text={`https://${game.site.toLowerCase()}.com${routePrefix}/listing/${
                    listing.id
                  }${isAuction ? '?auction' : ''}`}
                  label={<IoIosShareAlt style={{ fontSize: 25 }} />}
                  style={{
                    background: 'transparent',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                  }}
                />
              </div>
              {isMultiListing ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ alignSelf: 'flex-end' }}>
                    {listing.selling && currUser.id !== listing.seller_id && (
                      <Tooltip
                        text={<Trans i18nKey='reportUser' />}
                        className='listing-report-tooltip'
                      >
                        &nbsp;
                        <IoIosFlag
                          className='listing-report-icon'
                          style={{ fontSize: '25px' }}
                          onClick={() => setReporting(true)}
                        />
                      </Tooltip>
                    )}
                  </span>
                  <ListingData
                    key={listing.id}
                    canSell={false}
                    history={history}
                    horizontal={false}
                    listing={listing}
                    listView={true}
                    // product={product}
                    profile={listing.seller_id}
                    profileLink={true}
                    showDetails={true}
                    showRecipes={true}
                  />
                </div>
              ) : (
                <ListingInfo
                  game={game}
                  routePrefix={routePrefix}
                  listing={listing}
                  timeLeft={timeLeft}
                  currUser={currUser}
                  history={history}
                  updateListing={updateListing}
                  reportListing={() => {
                    setReporting(true)
                  }}
                  editListing={() => {
                    setEditing(true)
                  }}
                />
              )}
              <ModActions
                listing={listing}
                viewAllOffers={() => {
                  setViewAllOffers(true)
                }}
                routePrefix={routePrefix}
                isAuction={isAuction}
                timeLeft={timeLeft}
                getListing={getListing}
                history={history}
              />
              {!currUser.id && (
                <div style={{ marginBottom: 15 }}>
                  <StyledButtonLink
                    id='listing-signup-btn'
                    to={{
                      pathname: '/signup',
                      state: { from: location.pathname },
                    }}
                    style={{ display: 'inline-block', padding: '5px 10px' }}
                  >
                    <Trans i18nKey='signUp' />
                  </StyledButtonLink>{' '}
                  <Trans i18nKey='listingLoggedOut' />
                </div>
              )}
              {listing.end_time && (
                <div style={{ marginBottom: 10 }}>
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    <Trans i18nKey='warning' />:{' '}
                  </span>
                  <Trans i18nKey='listingWarning' />
                </div>
              )}
              {listing.item_id === '1060718505' && (
                <div style={{ marginBottom: 10 }}>
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    <Trans i18nKey='warning' />:{' '}
                  </span>
                  <Trans i18nKey='moveOutWarning' />
                </div>
              )}
              {can('USERS:SCRAPED', listing.seller) && (
                <div style={{ marginBottom: 10 }}>
                  <Trans i18nKey='scrapeListing' />
                  &nbsp;
                  <Trans i18nKey='scrapeUser' />
                </div>
              )}
              {!can('USERS:SCRAPED', listing.seller) &&
                listing.id &&
                currUser.id && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: offering ? 'column' : 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {(!noListingOffer ||
                        (canOffer && listing.selling === true)) &&
                        !isSeller && (
                          <div className='add-listing-container'>
                            {game.has('OFFERS:CATALOG') ? (
                              <MakeOfferCatalog
                                offering={offering}
                                onMake={(offering) => {
                                  setOffering(offering)
                                  if (offering === false) setDrawerOpen(true)
                                }}
                                listing={listing}
                              />
                            ) : (
                              <MakeOffer
                                offering={offering}
                                onMake={(offering) => {
                                  setOffering(offering)
                                  if (offering === true) {
                                    setIsTourRunning(false)
                                  }
                                  if (offering === false) {
                                    if (
                                      location.pathname.includes(
                                        `/listing/${listing.id}`
                                      )
                                    ) {
                                      window.scrollTo(0, 0)
                                    }
                                    setDrawerOpen(true)
                                  }
                                }}
                                onCancel={() => {
                                  setOffering(false)
                                }}
                                listing={listing}
                                history={history}
                                match={match}
                                currUser={currUser}
                                offset={
                                  history.action === 'PUSH'
                                    ? (topRef.current || {}).offsetTop
                                    : 0
                                }
                              />
                            )}
                          </div>
                        )}
                      {!noListingOffer &&
                        canOffer &&
                        listing.selling === false && (
                          <OfferListing
                            listing={listing}
                            setOffering={setOffering}
                            setCanOffer={() => {
                              setCanOffer(true)
                            }}
                          />
                        )}
                      {!offering && (
                        <StyledOffersTab>
                          <Trans
                            i18nKey={getModeLabel(item.mode, 'offers')}
                            style={{ verticalAlign: 'middle' }}
                          />
                          &nbsp;&nbsp;
                          <MdLocalOffer style={{ verticalAlign: 'middle' }} />
                        </StyledOffersTab>
                      )}
                    </div>
                    {!offering && (
                      <SocketContext.Consumer>
                        {(socket) => (
                          <Offers
                            listing={listing}
                            viewAllOffers={viewAllOffers}
                            offering={offering}
                            socket={
                              itemHas(listing.item.mode, 'LISTINGS:LIVE')
                                ? socket
                                : null
                            }
                            onOffers={(offers) => {
                              let canOfferNow = true
                              if (listing.seller.id + '' === currUser.id)
                                canOfferNow = false
                              if (listing.completed) canOfferNow = false
                              if (!listing.end_time) {
                                // Regular
                                let hasUserOffered = false
                                offers.forEach((o) => {
                                  if (
                                    o.buyer &&
                                    o.buyer.id + '' === currUser.id
                                  )
                                    hasUserOffered = true
                                })
                                if (hasUserOffered) canOfferNow = false
                              } else {
                                // Auction
                                // If auction is over
                                if (
                                  DateTime.fromISO(listing.end_time)
                                    .diffNow()
                                    .as('milliseconds') <= 0
                                )
                                  canOfferNow = false

                                // If user is most recent bid
                                let topBid = offers[0]
                                if (
                                  topBid &&
                                  topBid.buyer &&
                                  currUser.id === topBid.buyer.id + ''
                                )
                                  canOfferNow = false
                              }
                              if (can('MUTE:MAKE_OFFER')) canOfferNow = false

                              setCanOffer(canOfferNow)
                            }}
                            match={match}
                            history={history}
                            tutorial={currentTutorial}
                          />
                        )}
                      </SocketContext.Consumer>
                    )}
                  </div>
                )}
              <AdSlot
                name='pb-slot-incontent-1'
                divId='listing-ad-1'
                tablet
                mobile
              />
            </div>
            <AdSlot
              name='pb-slot-right-2'
              divId='listing-ad-2'
              tablet={false}
              data-display-type='hybrid-banner'
              containerStyle={{
                alignItems: 'flex-start',
                marginLeft: 10,
                position: 'sticky',
                top: 20,
              }}
            />
          </span>
          <div className='other-listings'>
            <StyledLink
              to={`${routePrefix}/profile/${
                listing.seller.id
              }/${game.getProfileDefault()}`}
              className='home-title-container'
              aria-label='Other Listings From This Seller'
            >
              <div className='other-listings-header'>
                <Trans i18nKey='otherUserListings' />
              </div>
            </StyledLink>
            <Listings
              grid={4}
              hideLoad
              hideView
              history={history}
              horizontal
              location={location}
              size={5}
              slider={isCompactView ? false : true}
              profile={listing.seller_id}
              user={listing.seller}
              vertical={isCompactView ? false : true}
            />
          </div>
        </div>
        <AdSlot name='pb-slot-incontent-2' divId='listing-ad-3' />
      </div>
      {!mobileView && (
        <CartDrawer
          show={drawerOpen}
          onClose={() => {
            setDrawerOpen(!drawerOpen)
          }}
          history={history}
        />
      )}
      <Report
        user={listing.seller}
        open={reporting}
        onClose={() => {
          setReporting(false)
        }}
      />
      {currAlert !== '' && (
        <Alert removeAlert={removeAlert} alert={currAlert} />
      )}
      <Tutorial
        steps={tutorialSteps}
        run={isTourRunning}
        last={currentTutorial && currentTutorial === 'Listing Tutorial'}
        progressKey={'listingTutorialSteps'}
      />
    </>
  )
}

export default Listing
