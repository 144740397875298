import React, { useContext, useState } from 'react'
import { FaUserSlash } from 'react-icons/fa'
import { IoIosSettings, IoIosShareAlt } from 'react-icons/io'
import { HiFlag, HiClipboardList } from 'react-icons/hi'
import { components } from 'react-select'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import UserContext from '../../../contexts/UserContext'
import can from '../../../services/rbac'
import { startConvo } from '../../../services/messages'
import { setUser } from '../../../services/users'

import { Verified } from '../../../components/User'
import { StyledSelect, StyledLink } from '../../../components/Styled'
import Alert from '../../../components/Alert'
import CopyButton from '../../../components/inputs/CopyButton'
import Dropdown from '../../../components/Navbar/Dropdown'
import Ranking from '../../../components/User/Ranking'
import Username from '../../../components/User/Username'
import UserStatus from '../../../components/User/UserStatus'

import ProfileActions from './ProfileActions'
import MessageRequest from './MessageRequest'

const statusOptions = [
  { value: 'online', label: 'Online' },
  { value: 'busy', label: 'Busy' },
  { value: 'offline', label: 'Offline' },
]

const ProfileTopContainer = styled.div`
  display: ${({ actions }) => (actions ? 'flex' : 'none')};
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const ProfileTopInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: ${({ actions }) => (actions ? 'block' : 'flex')};
    width: 100%;
  }
`

const HideMobile = styled.div`
  @media only screen and (max-width: 600px) {
    display: ${({ actions }) => (actions ? 'none' : '')};
  }
`

const ProfileName = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-right: 10px;
  word-break: break-all;

  @media only screen and (max-width: 600px) {
    display: ${({ actions }) => (actions ? 'none' : '')};
    font-size: 1.25rem;
  }
`

const ProfileStatus = styled(HideMobile)`
  width: auto;
  margin-right: 10px;
`

const isMobile = window.innerWidth <= 600

const statusStyle = { fontSize: 12, marginRight: 5 }

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <UserStatus status={data.label} style={statusStyle} />
  </components.SingleValue>
)

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <UserStatus status={data.label} style={statusStyle} />
    </components.Option>
  )
}

const ProfileTop = ({
  canSell,
  currUser,
  game,
  routePrefix,
  history,
  isBlocked,
  userBlocked,
  openReport,
  setBlockDialog,
  unblockUser,
  updateUser,
  user,
  actions,
}) => {
  const [requesting, setRequesting] = useState(false)
  const [currAlert, setAlert] = useState('')
  const { ChatIcon } = game.icons
  const userContext = useContext(UserContext)
  let muted
  if (user.roles) {
    muted = user.roles.find((role) => role.title === 'muted-user')
  }
  let status =
    statusOptions.find((o) => o.value === user.status) || statusOptions[2]
  if (user && userContext.user) {
    if (muted && Number(user.id) === Number(userContext.user.id)) {
      status = statusOptions.find((o) => o.value === userContext.user.status)
    }
  }

  let dropdownItems = []

  if (!canSell && !user.banned) {
    dropdownItems.push({
      icon: <HiFlag style={{ color: '#ea685e' }} size={'1.5em'} />,
      onClick: openReport,
      content: <Trans i18nKey='reportUser' />,
    })
    if (!can('ADMIN:UNBLOCKABLE', user)) {
      if (isBlocked) {
        dropdownItems.push({
          icon: <FaUserSlash style={{ color: 'gray' }} size={'1.5em'} />,
          onClick: () => {
            unblockUser()
          },
          content: <Trans i18nKey='unblockUser' />,
        })
      } else {
        dropdownItems.push({
          icon: <FaUserSlash style={{ color: 'gray' }} size={'1.5em'} />,
          onClick: () => {
            setBlockDialog(true)
          },
          content: <Trans i18nKey='blockUser' />,
        })
      }
    }
  }

  if (can('ADMIN:CHAT'))
    dropdownItems.push({
      icon: <ChatIcon size={'1.5em'} />,
      onClick: () => {
        startConvo({ user: user, asMod: true }).then(() => {
          history.push(`${routePrefix}/chat/${user.id}`)
        })
      },
      content: 'Start Chat',
    })

  if (can('ADMIN:UNBLOCKABLE'))
    dropdownItems.push({
      icon: <HiClipboardList size={'1.5em'} />,
      content: <Trans i18nKey='adminPanel' />,
      onClick: () => {
        history.push(`${routePrefix}/admin/users/${user.id}`)
      },
    })

  const removeAlert = () => setAlert('')

  return (
    <ProfileTopContainer actions={actions}>
      <ProfileTopInfo actions={actions}>
        <ProfileName actions={actions}>
          {user.banned && '[BANNED] '}
          {isBlocked && '[BLOCKED] '}
          <Username user={user} />
          {game.site === 'Traderie' && <Verified roles={user.roles} />}
          {user.score !== undefined && user.leaderboard_optin && (
            <Ranking
              score={user.score}
              roles={user.roles}
              size={window.innerWidth > 600 ? 60 : 20}
            />
          )}
        </ProfileName>
        {!user.banned && (
          <ProfileStatus
            actions={actions}
            style={{ minWidth: canSell ? 150 : '' }}
          >
            {canSell && (
              <StyledSelect
                classNamePrefix='Select'
                options={statusOptions}
                value={status}
                components={{ SingleValue, Option }}
                isSearchable={false}
                onChange={(status) => {
                  if (!muted) {
                    updateUser('status', status.value).then((err) => {
                      if (err) return setAlert(err)
                    })
                  }
                  user.updated_at = new Date()
                  user.status = status.value
                  userContext.setUser(user)
                  setUser(user)
                }}
              />
            )}
            {!canSell && (
              <UserStatus
                status={status.label}
                style={{
                  fontSize: 12,
                  marginRight: 5,
                }}
              />
            )}
          </ProfileStatus>
        )}
        {actions && (
          <ProfileActions
            routePrefix={routePrefix}
            user={user}
            currUser={currUser}
            isBlocked={isBlocked}
            userBlocked={userBlocked}
            canSell={canSell}
            requestMessage={() => setRequesting(true)}
          />
        )}
      </ProfileTopInfo>
      <HideMobile actions={actions}>
        {canSell ? (
          <div style={{ display: 'flex' }}>
            {!isMobile && (
              <StyledLink
                id='profile-settings-btn'
                to={`${routePrefix}/profile/${currUser.id}/settings#timezone`}
                className='btn-icon'
                aria-label='Settings'
              >
                <IoIosSettings style={{ fontSize: 30 }} />
              </StyledLink>
            )}
            <CopyButton
              text={`https://${game.site.toLowerCase()}.com${routePrefix}/profile/${
                currUser.id
              }/listings`}
              label={<IoIosShareAlt style={{ fontSize: 30 }} />}
              style={{
                background: 'transparent',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            />
          </div>
        ) : (
          currUser.id && <Dropdown items={dropdownItems} />
        )}
      </HideMobile>
      {requesting && (
        <MessageRequest
          routePrefix={routePrefix}
          user={user}
          currUser={currUser}
          requesting={requesting}
          setRequesting={setRequesting}
        />
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </ProfileTopContainer>
  )
}

export default ProfileTop
