import React from 'react'
import qs from 'qs'
import styled from 'styled-components'
import _ from 'lodash'
import { FaSave } from 'react-icons/fa'

import GameContext from '../../../GameContext'
import {
  setPreferredProps,
  setCreatePreferredProps,
} from '../../../services/properties'

import DeleteFilterBtn from '../DeleteFilterBtn'
import { StyledInput } from '../../Styled'
import { Checkbox } from '../../inputs'
import { StyledCheckbox, StyledButton } from '../MinMaxFilter'

import './style.css'

const StyledDropdown = styled.div`
  position: absolute;
  top: ${({ overflow }) => (overflow ? '-200px' : '30px')};
  left: ${({ isOverflown }) => (isOverflown ? 'undefined' : '-2px')};
  right: ${({ isOverflown }) => (isOverflown ? '-40px' : 'undefined')};
  width: 250px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ toggle, theme }) =>
    toggle ? theme.dropdownMenu : theme.filterActiveBg};
  background: ${({ toggle, theme }) => (toggle ? '' : theme.dropdownMenu)};
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  padding-bottom: 0;
  overflow: hidden;
  transition: ${(props) => props.height} var(--speed) ease;
  z-index: 5;
  overflow-y: scroll;
  max-height: ${({ overflow }) => (overflow ? '200px' : '400px')};
`

const StyledBtnBar = styled.div`
  background: ${({ toggle, theme }) => (toggle ? '' : theme.dropdownMenu)};
  padding-bottom: 0.5rem;
`

class MultiSelectFilter extends React.Component {
  state = {
    dropdownOpen: this.props.sidebarStyle ? true : false,
    propName: '',
    propOptions: [],
    selected: false,
    searchOptions: [],
    search: '',
  }
  dropdownRef = React.createRef()
  checkboxRef = React.createRef()

  static contextType = GameContext

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false)
    const { game } = this.context
    const { name, initOptions, itemProperties, property } = this.props
    let initPropOptions = []
    const itemOptions = (itemProperties || []).map((option) => ({
      option: option,
      isChecked: false,
    }))
    if (initOptions) {
      if (property.type === 'bool') {
        initPropOptions = [
          {
            option: itemProperties[initOptions === 'true' ? 0 : 1],
            isChecked: true,
          },
        ]
      } else {
        initPropOptions = initOptions.split(',').map((option) => ({
          option: option,
          isChecked: true,
        }))
      }
    }
    const propOptions = _.unionBy(initPropOptions, itemOptions, 'option')
    this.setState({
      propName: name,
      propOptions: propOptions,
    })

    if (property.format && !property.format.preferred)
      setPreferredProps(game.schema, { [property.id]: null })
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  // True if dropdown is overflowing to the right of viewport
  isOverflownRight = () => {
    const leftPosition =
      this.checkboxRef?.current?.getBoundingClientRect()?.left
    return window.innerWidth < leftPosition + 250
  }

  handleClick = (e) => {
    let { onClose } = this.props
    let { dropdownOpen } = this.state

    // Do nothing if click within dropdown menu
    if (
      dropdownOpen &&
      (this.dropdownRef.current.contains(e.target) ||
        this.checkboxRef.current.contains(e.target))
    ) {
      return
    } else if (dropdownOpen) {
      // close window if click is outside of ref and either dropdowns are open
      if (onClose) onClose()
      this.setState({
        dropdownOpen: false,
        search: '',
        searchOptions: [],
      })
    }
  }

  updatePropertyQuery = (save) => {
    const { propName, propOptions } = this.state
    const {
      itemProperties,
      property,
      updatePropFilters,
      updateQuery,
      currProps,
      setCurrProps,
    } = this.props

    if (property.type === 'multiselect') {
      const selectedPropOptions = propOptions
        .filter((po) => po.isChecked)
        .map((p) => p.option)
      const addProps = currProps.filter((p) =>
        selectedPropOptions.includes(p.property)
      )
      let multiUpdate = {}
      for (const addProp of addProps) {
        delete addProp.format?.group
        if (addProp.type === 'bool') {
          multiUpdate[`prop_${addProp.property}`] = true
        }
        const addIdx = currProps.findIndex((p) => p.id === addProp.id)
        currProps[addIdx] = addProp
      }
      setCurrProps(currProps)
      if (Object.keys(multiUpdate).length > 0) {
        updateQuery({ ...multiUpdate })
      } else {
        updateQuery()
      }
      this.setState({ dropdownOpen: false, search: '', searchOptions: [] })
      return
    }

    const filteredOptions = propOptions
      .filter((x) => x.isChecked === true)
      .map((x) => x.option)
    this.setState({ dropdownOpen: false, selected: true })
    let option = filteredOptions.length === 0 ? null : filteredOptions.join(',')

    if (property.type === 'bool' && itemProperties) {
      const propIndex = itemProperties.findIndex((pO) => pO === option)
      if (propIndex >= 0) option = propIndex === 0
    }

    const update = {
      [`prop_${propName}`]: option,
    }

    if (updatePropFilters) {
      updatePropFilters({ ...update })
    } else {
      updateQuery({ ...update })
    }

    if (save === true && property.format && property.format.preferred) {
      const { game } = this.context
      setPreferredProps(game.schema, {
        [property.id]: option === null ? option : { ...property, option },
      })
      setCreatePreferredProps(game.schema, {
        [property.id]: option === null ? option : { ...property, option },
      })
    }
  }

  isValid = () => {
    let { location } = this.props
    let { propName } = this.state
    let query = qs.parse(location.search.substring(1))

    if (query[`prop_${propName}`]) {
      return true
    } else {
      return false
    }
  }

  clear = () => {
    let { updateQuery, itemProperties } = this.props
    let { propName } = this.state
    let itemOptions = (itemProperties || []).map((option) => ({
      option: option,
      isChecked: false,
    }))
    this.setState({
      dropdownOpen: false,
      propOptions: itemOptions,
      selected: false,
      search: '',
      searchOptions: [],
    })
    let update = { propertyFilters: {} }
    if (propName) {
      let propKey = `prop_${propName}`
      update[propKey] = null
    }
    updateQuery({ ...update })
  }

  render = () => {
    const { property, style, overflow, sidebarStyle, onDelete } = this.props
    const {
      propName,
      propOptions,
      dropdownOpen,
      selected,
      search,
      searchOptions,
    } = this.state
    const toggle = !this.isValid()
    if (sidebarStyle) {
      style['background'] = 'none'
      style['border'] = 'none'
      style['font-weight'] = 'bold'
    }

    return (
      <>
        <StyledCheckbox
          sidebar={sidebarStyle}
          toggle={!toggle}
          ref={this.checkboxRef}
          style={{
            ...style,
          }}
          onClick={() => {
            if (!dropdownOpen) this.setState({ dropdownOpen: !dropdownOpen })
          }}
        >
          <div
            className='min-max-filter'
            onClick={() => {
              this.setState({ dropdownOpen: !dropdownOpen })
            }}
          >
            {property.type === 'accepting' && 'Accepting '}
            {_.upperFirst(propName)}
            {onDelete && !toggle && (
              <DeleteFilterBtn
                onClick={() => {
                  onDelete()
                  this.clear()
                }}
                style={{ marginBottom: '-4px' }}
              />
            )}
          </div>
          {dropdownOpen && !sidebarStyle && (
            <StyledDropdown
              ref={this.dropdownRef}
              style={{
                textAlign: 'center',
              }}
              overflow={overflow}
              isOverflown={this.isOverflownRight()}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <StyledInput
                  value={search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value })
                    const newOptions = propOptions.filter((prop) =>
                      prop.option
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                    this.setState({ searchOptions: newOptions })
                  }}
                  className='listing-search-input'
                  style={{
                    background: 'transparent',
                    marginBottom: '4px',
                  }}
                  placeholder='Search Properties'
                />
                {searchOptions.length > 0 ? (
                  <>
                    {searchOptions &&
                      searchOptions.map((option, index) => {
                        return (
                          <Checkbox
                            label={option.option}
                            labelKey={index}
                            translateLabel={property.type === 'accepting'}
                            checked={option.isChecked}
                            onChange={(e) => {
                              const pIdx = propOptions.findIndex(
                                (p) => p.option === option.option
                              )
                              propOptions[pIdx].isChecked = e.target.checked
                              this.setState({ propOptions })
                            }}
                          />
                        )
                      })}
                  </>
                ) : (
                  <>
                    {propOptions &&
                      propOptions.map((option, index) => {
                        return (
                          <Checkbox
                            label={option.option}
                            labelKey={index}
                            checked={option.isChecked}
                            translateLabel={property.type === 'accepting'}
                            onChange={(e) => {
                              propOptions[index].isChecked = e.target.checked
                              this.setState({ propOptions })
                            }}
                          />
                        )
                      })}
                  </>
                )}
              </div>
              <div style={{ margin: 5 }} ref={this.dropdownRef}></div>
              <StyledBtnBar className='multi-select-btn-bar'>
                <StyledButton onClick={this.clear} aria-label='Clear'>
                  Clear
                </StyledButton>
                <button onClick={this.updatePropertyQuery} aria-label='Done'>
                  Done
                </button>
                {property.format && property.format.preferred && (
                  <button
                    onClick={() => {
                      this.updatePropertyQuery(true)
                    }}
                    className='multi-lock'
                    aria-label='Save'
                  >
                    <FaSave style={{ marginRight: 8 }} /> Save
                  </button>
                )}
              </StyledBtnBar>
            </StyledDropdown>
          )}
        </StyledCheckbox>
        {sidebarStyle && (
          <div>
            <div className='sidebar-property-dropdown'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {propOptions &&
                  propOptions.map((option, index) => {
                    if (selected && !option.isChecked) {
                      return
                    }
                    return (
                      <Checkbox
                        key={index}
                        label={option.option}
                        checked={option.isChecked}
                        onChange={(e) => {
                          propOptions[index].isChecked = e.target.checked
                          this.setState({ propOptions })
                        }}
                      />
                    )
                  })}
              </div>
              <div style={{ margin: 5 }} ref={this.dropdownRef}></div>
            </div>
            <div className='multi-select-btn-bar-sidebar'>
              <StyledButton onClick={this.clear} aria-label='Clear'>
                Clear
              </StyledButton>
              {!selected && (
                <button onClick={this.updatePropertyQuery} aria-label='Done'>
                  Done
                </button>
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default MultiSelectFilter
