import React from 'react'
import { Trans } from 'react-i18next'
import { itemHas } from 'item-mode'
import { DateTime } from 'luxon'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { getUser } from '../../../services/users'
import { startConvo } from '../../../services/messages'

import Button from '../../inputs/Button'

import AcceptOffer, { acceptOffer } from './AcceptOffer'
import AddPref from './AddPref'
import CancelOffer from './CancelOffer'
import CompleteOffer from './CompleteOffer'
import DenyOffer from './DenyOffer'
import OnMyWay from './OnMyWay'
import ReOpenOffer from './ReOpenOffer'
import Alert from '../../Alert'

import './style.css'
import SocketContext from '../../../contexts/SocketContext'

export const updateDodo = ({
  dodo,
  on_the_way,
  offer,
  listing,
  diy,
  buyer,
  seller,
  game,
}) => {
  if (dodo && !game.validateCode(dodo)) {
    return new Promise((resolve) => {
      resolve({
        error: `${game.code.errorKey || ''} Invalid ${game.code.name} code.`,
      })
    })
  }

  let payload = {
    dodo,
    on_the_way,
    offer,
    listing,
    diy,
  }

  // Set if you want to send a notif to buyer
  if (buyer) payload.buyer = buyer
  // Set if you want to send a notif to seller
  if (seller) payload.seller = seller
  return http.put('/offers/dodo', payload)
}

class OfferActions extends React.Component {
  state = {
    accepting: false,
    currAlert: '',
  }
  static contextType = GameContext

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let user = getUser()
    let userId = user.id

    let { offer, updateOffers, hasCounter, isChat, isListingPage, history } =
      this.props
    const { game, routePrefix } = this.context

    let {
      listing,
      buyer,
      accepted,
      dodo,
      on_the_way,
      parent_user,
      dodo_user_id,
    } = offer
    let { seller, auction_over, item } = listing
    let isSeller = userId === seller.id + '' || parent_user === userId
    let isBuyer = buyer && userId === buyer.id + ''
    let isAuction = game.has('LISTINGS:AUCTIONS') && listing.end_time !== null
    let isAuctionOver =
      isAuction &&
      DateTime.fromISO(listing.end_time).diffNow().as('milliseconds') <= 0
    let isAuctionWinner = isAuctionOver && isBuyer && isListingPage
    const expiredNext =
      listing.completed &&
      new Date(listing.updated_at).getTime() + 1000 < new Date().getTime()

    let { accepting, currAlert } = this.state
    const liveListing = itemHas(item.mode, 'LISTINGS:LIVE')

    return (
      <div
        className='offer-actions'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {((accepted && isBuyer) || isSeller || isAuctionWinner) &&
          !isChat &&
          !hasCounter && (
            <div className='offer-action-btn-container'>
              <Button
                onClick={() => {
                  startConvo({
                    user: {
                      id: isBuyer ? seller.id + '' : buyer.id + '',
                      username: isBuyer ? seller.username : buyer.username,
                    },
                    offer: offer.id,
                  }).then(() => {
                    history.push(
                      `${routePrefix}/chat/${
                        isBuyer ? seller.id : buyer.id
                      }/listings`
                    )
                  })
                }}
                className='btn-3'
                label={<Trans i18nKey='message' />}
              />
            </div>
          )}
        {accepted === null && isSeller && !hasCounter && (
          <div className='offer-actions-row'>
            {!accepting && (listing.auction_over || true) && (
              <div className='offer-action-btn-container'>
                <SocketContext.Consumer>
                  {(socket) => (
                    <Button
                      onClick={() => {
                        if (game.has('OFFERS:DODO')) {
                          this.setState({ accepting: true })
                        } else {
                          acceptOffer({
                            offer,
                            listing,
                            updateOffers,
                            game,
                          }).then((error) => {
                            if (error)
                              return this.setState({ currAlert: error })
                            window.dataLayer.push({
                              event: 'acceptedOffer',
                              eventProps: {
                                category: 'Offer Actions',
                                action: 'User selects accept offer',
                              },
                              userId: user ? user.id : undefined,
                              email: user ? user.email : undefined,
                            })
                            if (liveListing) {
                              socket.emit('group_accept', listing.id, offer.id)
                            }
                          })
                        }
                      }}
                      label={<Trans i18nKey='accept' />}
                      className='offer-accept-btn'
                    />
                  )}
                </SocketContext.Consumer>
              </div>
            )}
            {!accepting && !isAuction && (
              <DenyOffer offer={offer} updateOffers={updateOffers} />
            )}
            {accepting && (
              <AcceptOffer
                onAccept={(accepting) => {
                  this.setState({ accepting })
                }}
                offer={offer}
                updateOffers={updateOffers}
                expired={expiredNext}
              />
            )}
          </div>
        )}
        {game.has('OFFERS:DODO') &&
          accepted &&
          on_the_way === false &&
          dodo !== null &&
          dodo_user_id === userId &&
          (isSeller || isBuyer) && (
            <OnMyWay offer={offer} updateOffers={updateOffers} />
          )}
        {game.has('OFFERS:ADDPREF') && accepted && isSeller && (
          <AddPref offer={offer} updateOffers={updateOffers} />
        )}
        {!isAuction &&
          userId === seller.id + '' &&
          accepted &&
          !liveListing && (
            <ReOpenOffer offer={offer} updateOffers={updateOffers} />
          )}
        {(accepted !== null ||
          (isAuction &&
            ((auction_over &&
              (listing.completed || !listing.active) &&
              DateTime.fromISO(listing.end_time).diffNow().as('hours') <=
                -48) ||
              listing.completed))) &&
          (isSeller || isBuyer) && (
            <CompleteOffer
              offer={offer}
              updateOffers={updateOffers}
              history={history}
            />
          )}
        {accepted && (
          <div className='offer-action-btn-container'>
            {isSeller && buyer.roblox_id && game.has('USERS:ROBLOX') && (
              <button
                onClick={() => {
                  window.open(
                    `https://www.roblox.com/users/${buyer.roblox_id}/profile`,
                    'blank'
                  )
                }}
              >
                <Trans i18nKey='tradeRoblox' />
              </button>
            )}
            {isBuyer && seller.roblox_id && game.has('USERS:ROBLOX') && (
              <button
                onClick={() => {
                  window.open(
                    `https://www.roblox.com/users/${seller.roblox_id}/profile`,
                    'blank'
                  )
                }}
              >
                <Trans i18nKey='tradeRoblox' />
              </button>
            )}
          </div>
        )}
        {accepted !== false && !isAuction && isBuyer && !hasCounter && (
          <CancelOffer offer={offer} updateOffers={updateOffers} />
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default OfferActions
