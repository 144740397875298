import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import qs from 'qs'
import {
  FaEnvelopeOpenText,
  FaCheckCircle,
  FaBan,
  FaHistory,
  FaGavel,
  FaHeart,
  FaShoppingCart,
} from 'react-icons/fa'
import styled, { withTheme } from 'styled-components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { itemHas } from 'item-mode'

import GameContext from '../../GameContext'
import http, { useQuery } from '../../services/http'
import { getUser } from '../../services/users'
import { NavTab, StyledTab } from '../Styled'
import SearchBar from '../inputs/SearchBar'
import Button from '../inputs/Button'
import { startGroupConvo } from '../../services/messages'
import { trackQuest } from '../../services/quests'
import {
  skipTutorial,
  getCurrentTutorial,
  hasFinishedTutorial,
} from '../../services/tutorial'
import { isEmptyString } from '../../services/validate'

import AdSlot from '../AdSlot'
import ListingsFilters from '../Listings/ListingsFilters'
import OfferItem from './OfferItem'
import Alert from '../Alert'
import Tutorial from '../Tutorial'
import Modal from '../Modal'

import './style.css'

const OfferTabContainer = styled.div`
  display: flex;
  justify-content: space-btween;
  align-items: center;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.offerTabPadding || '0 12px'};

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

export const updateOffers = ({
  listing,
  offers,
  newOffers,
  concat,
  update,
  filter,
  remove,
}) => {
  let offerAccepted = false
  if (!listing && update && update.data.accepted !== undefined) {
    // For all pages that don't provide a listing
    offerAccepted = update.data.accepted
    remove = update.id
    update = undefined
  }

  let newState = { offering: false }

  if (newOffers) {
    newState.offers = newOffers
    newState.pageSize = newOffers.length
  }
  if (concat) {
    newState.offers = offers.concat(concat)
    newState.pageSize = concat.length
  }

  if (update) {
    const offerIdx = offers.findIndex((offer) => offer.id === update.id)
    if (offerIdx !== -1) {
      offers[offerIdx] = { ...offers[offerIdx], ...update.data }
      newState.offers = JSON.parse(JSON.stringify(offers))
    }
  }

  if (remove) {
    let foundIndex = offers.findIndex((o) => o.id === remove)
    if (foundIndex !== -1) {
      let offer = offers[foundIndex]
      listing = listing || offer.listing
      // do not remove from page if it is an auction, just update completed flag
      if (listing.end_time) {
        offers[foundIndex].completed = true
        newState.offers = offers
      }

      // If not standing listing remove all the other offers of the same listing
      if (!listing.standing && offerAccepted) {
        newState.offers = offers.filter((o) => o.listing.id !== listing.id)
      } else {
        newState.offers = offers.filter((o) => o.id !== remove)
      }
    } else {
      newState.offers = offers.filter((o) => o.id !== remove)
    }
  }

  if (filter) {
    newState.offers = newState.offers.filter(filter)
  }

  return newState
}

const isMobile = window.innerWidth <= 600

const initState = {
  activeFilters: [],
  firstCounter: false,
  hasCounter: false,
  loading: true,
  offering: false,
  offers: [],
  offerSearchLoading: false,
  page: 0,
  pageSize: 0,
  propFilters: {},
  searchTerm: '',
  currAlert: '',
  showTutorial: true,
  missingInfoModalOpen: false,
}

class Offers extends React.Component {
  state = initState
  static contextType = GameContext

  componentDidMount = () => {
    const user = getUser()
    const { socket } = this.props
    window.dataLayer = window.dataLayer || []
    if (user) {
      this.updateOffers({ getOffers: true })
      if (this.props.history)
        window.dataLayer.push({
          event: 'history',
          eventProps: {
            category: 'Offer History',
            action: `View ${this.props.isCart ? 'Cart' : 'Offers'} History`,
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
    }
    if (socket) {
      socket.on('player_request', () => {
        this.getOffers(true)
      })

      socket.on('player_denied', () => {
        this.getOffers(true)
      })
    }
  }

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  componentDidUpdate = (prev) => {
    let { location, match, isSeller, isCart, viewAllOffers } = this.props

    if (location) {
      let query = useQuery(location.search)
      let page = query.get('page')
      let searchQuery = query.get('search')

      let prevQuery = useQuery(prev.location.search)
      let prePage = prevQuery.get('page')
      let prevSearchQuery = prevQuery.get('search')

      if (
        page !== prePage ||
        match.params.status !== prev.match.params.status ||
        isSeller !== prev.isSeller ||
        prev.isCart !== isCart ||
        searchQuery !== prevSearchQuery ||
        location.search !== prev.location.search
      ) {
        // let status =
        //   match.params.status !== prev.match.params.status ||
        //   isSeller !== prev.isSeller ||
        //   prev.isCart !== isCart ||
        //   searchQuery !== prevSearchQuery
        this.getOffers(page === prePage)
        // this.receiveOffers({ getOffers: true, newStatus: status })
      }
    }
    if (viewAllOffers && viewAllOffers !== prev.viewAllOffers)
      this.getOffers(true, true)
  }

  getOffers = (clearOffers, mod) => {
    let {
      completed,
      isCart,
      isSeller,
      item,
      listing,
      location,
      match,
      recent,
      requests,
      variant,
    } = this.props
    let { page } = this.state
    let query = location && qs.parse(location.search.substring(1))

    let qsParams = {
      accepted: 'all',
    }
    if ((isSeller || isCart) && !completed) {
      qsParams.accepted = 'open'
    }
    if (page) qsParams.page = page
    if (listing) {
      qsParams.listing = listing.id
      qsParams.listing_owner = listing.seller.id
      if (listing.completed) qsParams.accepted = true

      //is completed auction, want to show all offers at all times
      if (listing.completed && listing.end_time) {
        qsParams.accepted = 'all'
        qsParams.completed = 'all'
      }
      qsParams.standingListing =
        listing.need_materials || listing.touch_trading || listing.standing

      if (!listing.selling) qsParams.looking_for = ''
    }
    if (isSeller) {
      qsParams.seller = getUser().id
    }
    if (isCart) {
      qsParams.user = getUser().id
    }
    if (completed) {
      qsParams.completed = 'true'
    }
    if (mod) {
      qsParams.accepted = 'all'
      qsParams.completed = ''
    }

    let status = ''
    let type = ''
    if (match && match.params) {
      status = match.params.status
      type = match.params.type
      if (status === 'accepted') {
        qsParams.accepted = true
      } else if (status === 'denied') {
        qsParams.accepted = false
        qsParams.denied = true
      } else if (status === 'history') {
        qsParams.accepted = 'all'
        qsParams.completed = true
        qsParams.includeCounterOffers = true
      }

      if (type === 'auctions') {
        qsParams.auctions = true
      } else if (type === 'lookingFor') {
        qsParams.lookingFor = true
      }
    }

    if (recent) {
      qsParams.properties = 'true'
      qsParams.completed = 'true'
      qsParams.accepted = true
    }
    if (requests) qsParams.requests = true
    if (item) qsParams.item = item.id
    if (variant) qsParams.variant = variant.id
    if (
      listing &&
      itemHas(listing.item.mode, 'LISTINGS:LIVE') &&
      listing.seller_id !== getUser().id
    ) {
      qsParams.user = getUser().id
      // qsParams.accepted = true
    }

    // Query Parameters
    if (query) {
      Object.assign(qsParams, query)
    }

    if (!mod) {
      const loadingState = { loading: true }
      if (clearOffers) loadingState.offers = []
      this.setState(loadingState)
    }
    http.get(`/offers`, qsParams).then((res) => {
      if (res.error) {
        this.setState({ loading: false })
        this.setState({ currAlert: res.error })
        return
      }
      if (listing)
        res.offers.forEach((offer) => {
          offer.listing = listing
        })

      let command = {}
      clearOffers
        ? (command.newOffers = res.offers)
        : (command.concat = res.offers)
      this.updateOffers(command)
    })
  }

  updateOffers = (params) => {
    let { listing, onOffers } = this.props
    let { offers } = this.state

    if (params.getOffers) this.getOffers()
    let newState = updateOffers({ ...params, listing, offers })
    if (newState.offers) newState.loading = false
    newState.offerSearchLoading = false
    this.setState(newState)
    if (onOffers && newState.offers) {
      onOffers(newState.offers)
    }
  }

  updateQuery = (update) => {
    let { history, location } = this.props
    let query = qs.parse(location.search.substring(1))
    let combined = { ...query, ...update }
    Object.keys(combined).forEach(
      (key) => combined[key] === null && delete combined[key]
    )
    if (update) {
      this.setState({ searchTerm: update.search, offerSearchLoading: true })
    }
    history.push({ search: qs.stringify({ ...combined }) })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { game } = this.context
    const {
      activeFilters,
      loading,
      offering,
      offers,
      offerSearchLoading,
      page,
      pageSize,
      propFilters,
      searchTerm,
      currAlert,
      showTutorial,
      missingInfoModalOpen,
    } = this.state

    const currUser = getUser()
    if (!currUser)
      return (
        <div>
          <Trans i18nKey='signInShopping' />
        </div>
      )

    const {
      adminPanel,
      completed,
      history,
      isCart,
      isOffersPage,
      isSeller,
      item,
      listing,
      location,
      match,
      product,
      recent,
      showAds,
      t,
      tutorial,
      url,
      viewAllOffers,
    } = this.props

    let buyerIds =
      offers && offers.length > 0 ? offers.map((offer) => offer.user_id) : []

    let liveListingSeller =
      offers &&
      offers.length > 0 &&
      offers.some((offer) => offer.listing.seller.id === parseInt(currUser.id))

    let countAcceptedOffers =
      offers && offers.length > 0
        ? offers.filter((offer) => offer.accepted).length
        : 0

    const query = location && qs.parse(location.search.substring(1))
    let { routePrefix } = this.context
    const cartPrefix = routePrefix
    if (!isCart && !isSeller) routePrefix = ''
    const { status } = match.params

    const isTourRunning = !hasFinishedTutorial()
    const currentTutorial = getCurrentTutorial()

    let myOffersTutorialSteps = [
      {
        target: '.offer-row:first-of-type',
        content:
          'This is the offer you just made! When it gets accepted, it will move to the accepted tab.',
        disableBeacon: true,
      },
      {
        target: '.offer-row:first-of-type',
        content: (
          <div>
            <p>
              This is the end of the Offers tutorial! Interested in learning how
              to create your own listing?
            </p>
            <button
              onClick={async () => {
                currentTutorial &&
                  (await skipTutorial(
                    currentTutorial === 'Offer Tutorial'
                      ? 'offerTutorialSteps'
                      : 'listingTutorialSteps',
                    location
                  ))
                this.setState({ showTutorial: false })
                if (
                  (game.hasContact('roblox_username') &&
                    isEmptyString(currUser.roblox_username)) ||
                  (game.has('USERS:ISLAND_VILLAGER_NAME') &&
                    (isEmptyString(currUser.villager_name) ||
                      isEmptyString(currUser.island_name)))
                ) {
                  this.setState({ missingInfoModalOpen: true })
                }
              }}
              style={{
                padding: '8px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                lineHeight: 1,
              }}
            >
              Skip
            </button>
          </div>
        ),
        disableBeacon: true,
        showProgress: false,
        placement: 'center',
        locale: {
          last: 'Next',
        },
        data: {
          homePage: `${routePrefix}`,
        },
      },
    ]

    let achievementPopupExists =
      document.getElementsByClassName('achievement-popup-container').length > 0

    let newOfferTutorialSteps = [
      {
        target: achievementPopupExists
          ? '.achievement-popup-container'
          : '.listing-content',
        content:
          'Congratulations on making your first offer! The other trader has received a notification about your offer.',
        disableBeacon: true,
        placement: achievementPopupExists ? 'top' : 'center',
      },
    ]

    if (isMobile) {
      newOfferTutorialSteps.push({
        target: '#mobileOffers',
        content: 'Click here to see your offers.',
        disableBeacon: true,
        spotlightClicks: true,
      })
    } else {
      newOfferTutorialSteps.push({
        target: '#create-dropdown',
        content:
          'To see your offers, go to Marketplace and click on My Offers.',
        disableBeacon: true,
      })
    }

    return (
      <div className='offers' id='listing-offers'>
        {(isSeller || isCart) && !completed && (
          <>
            <div style={{ marginTop: 10 }}>
              {isCart ? (
                <h2>
                  <Trans i18nKey='navOffersMade' />
                </h2>
              ) : (
                <h2>
                  <Trans i18nKey='navOffersReceived' />
                </h2>
              )}
            </div>
            <OfferTabContainer>
              <NavTab
                to={`${routePrefix}${url}/open`}
                onClick={() => this.setState({ searchTerm: '' })}
              >
                <Trans i18nKey='open' style={{ verticalAlign: 'middle' }} />
                &nbsp;&nbsp;
                <FaEnvelopeOpenText style={{ verticalAlign: 'middle' }} />
              </NavTab>
              <NavTab
                to={`${routePrefix}${url}/accepted`}
                onClick={() => this.setState({ searchTerm: '' })}
              >
                <Trans i18nKey='accepted' style={{ verticalAlign: 'middle' }} />
                &nbsp;&nbsp;
                <FaCheckCircle style={{ verticalAlign: 'middle' }} />
              </NavTab>
              <NavTab
                to={`${routePrefix}${url}/denied`}
                onClick={() => this.setState({ searchTerm: '' })}
              >
                <Trans i18nKey='denied' style={{ verticalAlign: 'middle' }} />
                &nbsp;&nbsp;
                <FaBan style={{ verticalAlign: 'middle' }} />
              </NavTab>
              <NavTab
                to={`${routePrefix}${url}/history`}
                onClick={() => this.setState({ searchTerm: '' })}
              >
                <Trans i18nKey='history' style={{ verticalAlign: 'middle' }} />
                &nbsp;&nbsp;
                <FaHistory style={{ verticalAlign: 'middle' }} />
              </NavTab>
            </OfferTabContainer>
          </>
        )}
        {!offering &&
          offers.length > 0 &&
          liveListingSeller &&
          listing &&
          itemHas(listing.item.mode, 'CHAT:GROUP_CHAT') &&
          listing.amount <= countAcceptedOffers && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '15px',
                marginBottom: '10px',
              }}
            >
              <Button
                onClick={() => {
                  startGroupConvo({
                    user: {
                      ids: buyerIds,
                    },
                    listing: listing,
                  }).then(() => {
                    http.post('/sell', { listing: listing.id }).then((res) => {
                      if (res.error) this.setState({ currAlert: res.error })
                      history.push(
                        `${cartPrefix}/chat/${listing.seller.id}/listings`
                      )
                    })
                  })
                }}
                className='btn-3'
                label={<Trans i18nKey='messageGroup' />}
              />
            </div>
          )}
        {recent && (
          <ListingsFilters
            filters={['properties']}
            history={history}
            item={item}
            location={location}
            query={query}
            updateQuery={this.updateQuery}
            activeFilters={activeFilters}
            setActiveFilters={(val) => this.setState({ activeFilters: val })}
            propFilters={propFilters}
            setPropFilters={(val) => this.setState({ propFilters: val })}
          />
        )}
        {isCart && (
          <div style={{ marginTop: 10 }}>
            <StyledTab
              to={`${routePrefix}${url}/${status}`}
              onClick={() => this.setState({ searchTerm: '' })}
              exact
            >
              <FaShoppingCart style={{ verticalAlign: 'middle' }} />
              &nbsp;
              <Trans i18nKey='all' style={{ verticalAlign: 'middle' }} />
            </StyledTab>
            {game.has('LISTINGS:AUCTIONS') && (
              <StyledTab
                to={`${routePrefix}${url}/${status}/auctions`}
                onClick={() => this.setState({ searchTerm: '' })}
                exact
              >
                <FaGavel style={{ verticalAlign: 'middle' }} />
                &nbsp;
                <Trans i18nKey='auctions' style={{ verticalAlign: 'middle' }} />
              </StyledTab>
            )}
            <StyledTab
              to={`${routePrefix}${url}/${status}/lookingFor`}
              onClick={() => this.setState({ searchTerm: '' })}
              exact
            >
              <FaHeart style={{ verticalAlign: 'middle' }} />
              &nbsp;
              <Trans i18nKey='lookingFor' style={{ verticalAlign: 'middle' }} />
            </StyledTab>
          </div>
        )}
        {!offering && (
          <div style={{ marginTop: 16 }}>
            {(isSeller || isCart) && (
              <SearchBar
                placeholder={t('searchItemsAndUsers')}
                search={searchTerm}
                updateQuery={this.updateQuery}
                loading={offerSearchLoading}
              />
            )}
            {offers.length > 0 && (
              <div className='offer-table'>
                {offers.map((offer, offerIdx) => {
                  const adCount = isMobile ? 5 : 10
                  return (
                    <>
                      <OfferItem
                        adminPanel={adminPanel}
                        history={history}
                        isListingPage={listing !== undefined}
                        isOffersPage={isOffersPage}
                        key={offerIdx}
                        match={match}
                        offer={offer}
                        offerIdx={offerIdx}
                        product={product}
                        updateOffers={this.updateOffers}
                      />
                      {showAds &&
                        offerIdx !== 0 &&
                        (offerIdx + 1) % adCount === 0 && (
                          <div className='col-xs-12'>
                            <AdSlot
                              name='pb-slot-incontent-1'
                              divId={`offer-ad-${offerIdx}`}
                            />
                          </div>
                        )}
                    </>
                  )
                })}
              </div>
            )}
            {loading &&
              Array(5)
                .fill()
                .map((index, idx) => {
                  return (
                    <div key={idx} className='offer-table'>
                      <SkeletonTheme
                        color={this.props.theme.bodyAlt}
                        highlightColor={this.props.theme.skeletonLoading}
                      >
                        <Skeleton
                          style={{ borderRadius: '20px', marginBottom: '15px' }}
                          height={150}
                        />
                      </SkeletonTheme>
                    </div>
                  )
                })}
            {!loading && offers.length === 0 && (
              <div className='no-listings'>
                {game.icons.noresults && (
                  <img
                    src={game.icons.noresults}
                    alt='resetti'
                    className='resetti'
                  />
                )}
                <Trans i18nKey='noOffers' />
              </div>
            )}
            {!loading && pageSize >= 20 && (
              <div className='see-all-btn-bar'>
                <button
                  onClick={() => {
                    this.setState({ page: page ? page + 1 : 1 }, () =>
                      this.getOffers(false, viewAllOffers ? true : false)
                    )
                    if (currUser) trackQuest('loadMore')
                  }}
                  aria-label='Load more'
                >
                  <Trans i18nKey='loadmore' />
                </button>
              </div>
            )}
          </div>
        )}
        {currAlert !== '' && (
          <Alert removeAlert={this.removeAlert} alert={currAlert} />
        )}
        {!loading &&
          location &&
          location.pathname.includes('/offers/made/') &&
          showTutorial && (
            <Tutorial
              steps={myOffersTutorialSteps}
              run={isTourRunning}
              last={currentTutorial && currentTutorial === 'Offer Tutorial'}
              progressKey={'offerTutorialSteps'}
            />
          )}
        {!offering &&
          tutorial === 'Offer Tutorial' &&
          offers.length > 0 &&
          currUser &&
          offers[0]?.buyer?.id === parseInt(currUser?.id) && (
            <Tutorial steps={newOfferTutorialSteps} run={isTourRunning} />
          )}
        {missingInfoModalOpen && (
          <Modal
            open={missingInfoModalOpen}
            label='Okay'
            btnClass='btn-alt'
            title='Missing Account Info'
            onCancel={() => {
              this.setState({ missingInfoModalOpen: false })
            }}
            style={{ maxWidth: '70vw', overflowY: 'auto' }}
            body={
              <>
                <p>
                  Whoops! It looks like you are missing your{' '}
                  {game.hasContact('roblox_username')
                    ? 'Roblox username'
                    : 'Villager or Island name'}{' '}
                  on your profile. You must add it before you can make any more
                  offers.
                </p>
              </>
            }
            onConfirm={() => {
              this.setState({ missingInfoModalOpen: false })
              history.push(`${routePrefix}/edit-profile/about-you`)
            }}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(withTheme(Offers))
