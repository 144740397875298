import React, { forwardRef } from 'react'
import { Trans } from 'react-i18next'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { HiOutlineX } from 'react-icons/hi'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { getUser } from '../../../services/users'

import { Checkbox } from '../../inputs'
import { StyledAsyncSelect, StyledErrorMessage, Beacon } from '../../Styled'
import ItemOption from './ItemOption'
import OfferTableGroup from './OfferTableGroup'
import SpecialCurrencies from './SpecialCurrencies'
import Tooltip from '../../Tooltip'

const GroupBreak = styled.div`
  font-size: 25px;
  margin: 8px 0px;
  padding: 0 8px;
  background: ${({ theme }) => theme.btnBackground};
  color: white;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`

const getOfferPrices = (listing) => {
  if (listing && listing.prices) {
    listing.prices.forEach((price) => {
      const offerProps = []
      if (price.properties) {
        price.properties.forEach((prop) => {
          if (prop[prop.type])
            offerProps.push({
              id: prop.id,
              type: prop.type,
              option: prop[prop.type],
            })
        })
      }
      if (offerProps.length > 0) price.offerProps = offerProps
    })
    return listing.prices
  }
  return []
}

class OfferTable extends React.Component {
  state = {
    items: getOfferPrices(this.props.listing),
    itemProperties: {},
    groups:
      this.props.listing?.prices?.length > 0 &&
      new Set(this.props.listing?.prices?.map((price) => price.group)).size >= 0
        ? new Set(this.props.listing?.prices?.map((price) => price.group)).size
        : 1,
    stepIndex: 0,
    run: true,
    currAlert: '',
    defaultOptions: [],
    catalogOnly: false,
  }

  static contextType = GameContext

  // Debounce item search
  itemsTimeout

  componentWillUnmount = () => {
    clearTimeout(this.itemsTimeout)
    this.abortController.abort()
  }

  getItemsDirect = (inputValue, callback, params) => {
    const { giveaway } = this.props
    let itemParams = {
      ...(params || {}),
      search: inputValue,
      variants: '',
      properties: '',
      tags: true,
    }
    if (this.state.catalogOnly) {
      const user = getUser()
      itemParams.catalog = user.id
      itemParams.cataloged = true
    }
    if (giveaway === undefined) itemParams['active'] = true
    http
      .get(`/items`, itemParams, { signal: this.abortController.signal })
      .then((res) => {
        const newItems = res.items.map((item) => ({
          value: item.id,
          label: item.name,
          variants: item.variants,
          canDiy: item.diy,
          img_url: item.img,
          properties: item.properties,
          canCatalog:
            item.tags &&
            item.tags.find((t) => t.tag === 'catalog') === undefined
              ? false
              : true,
        }))
        if (callback) {
          callback(newItems)
        } else {
          this.setState({ defaultOptions: newItems })
        }
      })
      .catch(() => {})
  }

  getItems = (inputValue, callback, params) => {
    const { listing } = this.props
    clearTimeout(this.itemsTimeout)
    this.itemsTimeout = setTimeout(() => {
      const acceptingTypes = listing?.properties?.filter(
        (prop) => prop.type === 'accepting' && prop.property === 'Types'
      )
      if (acceptingTypes?.length > 0) {
        // Accepting item types
        if (params === undefined) params = {}
        params.types = acceptingTypes.map((accept) => accept.string).toString()
        if (listing && listing.offer_wishlist) {
          params.wishlist_seller_id = listing.seller_id
          if (listing.offer_wishlist_id)
            params.or_wishlist = listing.offer_wishlist_id
        }
        this.getItemsDirect(inputValue, callback, params)
      } else if (listing && listing.offer_wishlist) {
        // Get wishlist items for wishlist trade
        const wishlistParams = {
          seller_id: listing.seller_id,
          active: true,
        }
        if (listing.offer_wishlist_id)
          wishlistParams.id = listing.offer_wishlist_id
        if (inputValue) wishlistParams.search = inputValue
        http
          .get(`/wishlist/items`, wishlistParams, {
            signal: this.abortController.signal,
          })
          .then((res) => {
            const newItems = res.items
              .filter(
                (itm) =>
                  !inputValue ||
                  itm.name.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item) => ({
                value: item.id,
                label: item.name + (item.listing_diy ? ' DIY' : ''),
                variants: item.variants,
                canDiy: item.diy,
                wishlistDiy: item.listing_diy === true,
                img_url: item.variant_img ? item.variant_img : item.img,
                properties: item.properties,
              }))
            callback(newItems)
          })
          .catch(() => {})
      } else {
        this.getItemsDirect(inputValue, callback, params)
      }
    }, 1000)
  }

  handlePropertyUpdate = (item, offerProperties) => {
    let { itemProperties } = this.state
    offerProperties.forEach((property) => {
      if (itemProperties[item.id]) {
        itemProperties[item.id].push(property)
      } else {
        itemProperties[item.id] = [property]
      }
    })
    this.setState(itemProperties)
  }

  // handleItemsChange = (value, option) => {
  //   let { items, offerProperties, groups } = this.state
  //   let { handleChange } = this.props
  //   let { game } = this.context
  //   let user = getUser()
  //   if (option.option) {
  //     // add one
  //     let getNewItem = option.option
  //     items.push({
  //       quantity: 1,
  //       diy: (getNewItem.wishlistDiy && !getNewItem.variants) || false,
  //       canDiy: getNewItem.diy,
  //       canCatalog: getNewItem.canCatalog,
  //       properties: offerProperties,
  //       variant: game.has('LISTINGS:NO_VARIANT_DEFAULT')
  //         ? {}
  //         : getNewItem.variants && getNewItem.variants.length > 0
  //         ? game.orderVariants(getNewItem.variants)[0]
  //         : null,
  //       ...getNewItem,
  //       group: groups,
  //     })
  //     this.setState({ items: items })
  //     const touch = items.find((i) => i.canCatalog)
  //     const diy = items.find((i) => i.canDiy)
  //     handleChange(
  //       items,
  //       touch === undefined ? false : true,
  //       diy === undefined ? false : true
  //     )
  //     window.dataLayer.push({
  //       event: 'createListing',
  //       eventProps: {
  //         category: 'Create Listing',
  //         action: 'Select Items to Trade',
  //       },
  //       userId: user ? user.id : undefined,
  //       email: user ? user.email : undefined,
  //     })
  //   } else if (option.removedValue) {
  //     let newItems = items.filter(
  //       (item) => item.value !== option.removedValue.value
  //     )
  //     this.setState({ items: newItems })
  //     const touch = newItems.find((i) => i.canCatalog)
  //     const diy = newItems.find((i) => i.canDiy)
  //     handleChange(
  //       newItems,
  //       touch === undefined ? false : true,
  //       diy === undefined ? false : true
  //     )
  //   } else if (!value) {
  //     // no item in trade
  //     this.setState({ items: [] })
  //     handleChange([], false)
  //   }
  // }

  reorder = (list, other, startIndex, endIndex) => {
    let result = Array.from(list)
    let [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result.concat(other)
  }

  move = (
    source,
    destination,
    other,
    droppableSource,
    droppableDestination
  ) => {
    let [removed] = source.splice(droppableSource.index, 1)
    removed.group = parseInt(droppableDestination.droppableId)
    destination.splice(droppableDestination.index, 0, removed)

    let res = other.concat(source)
    return res.concat(destination)
  }

  handleOnDragEnd = (result) => {
    let { items } = this.state
    let { source, destination, draggableId } = result

    if (!destination) {
      return
    }

    // Sorting in same list
    if (source.droppableId === destination.droppableId) {
      let newItems = this.reorder(
        items.filter((i) => i.group === parseInt(source.droppableId)),
        items.filter((i) => i.group !== parseInt(source.droppableId)),
        source.index,
        destination.index
      )

      this.setState({ items: newItems })
    }
    // Interlist movement
    else {
      const sameItem = items.filter(
        (i) => i.value + i.group + '' === draggableId
      )
      if (sameItem.length > 0)
        return this.setState({
          currAlert:
            'Cannot put the same item in a group twice, please change quantity instead',
        })

      let result = this.move(
        items.filter((i) => i.group === parseInt(source.droppableId)),
        items.filter((i) => i.group === parseInt(destination.droppableId)),
        items.filter(
          (i) =>
            i.group !== parseInt(source.droppableId) &&
            i.group !== parseInt(destination.droppableId)
        ),
        source,
        destination
      )

      this.setState({
        items: result,
      })
    }
  }

  deleteGroup = (group) => {
    const { items, groups } = this.state
    const { handleChange } = this.props
    const newItems = items.filter((i) => i.group !== group)
    newItems.forEach((item) => {
      if (item.group > group) {
        item.group = item.group - 1
      }
    })
    this.setState({ items: newItems, groups: groups - 1 })
    handleChange(newItems)
  }

  updateItems = ({ add, remove, update, replace, group }) => {
    const { items, offerProperties } = this.state
    const { handleChange, myItems } = this.props
    const { game } = this.context
    const limit = game.limits?.listingItems
    const user = getUser()

    let newItems = [...items]
    // add one
    if (add && limit && newItems.length >= limit && myItems)
      this.setState({ currAlert: `You may only have up to ${limit} items` })
    else this.setState({ currAlert: '' })
    if (
      add &&
      (!myItems || (myItems && (!limit || (limit && newItems.length < limit))))
    ) {
      const { canCatalog, diy, variants, wishlistDiy } = add
      newItems.push({
        quantity: 1,
        diy: (wishlistDiy && !variants) || false,
        canDiy: diy,
        canCatalog: canCatalog,
        properties: offerProperties,
        variant: game.has('LISTINGS:NO_VARIANT_DEFAULT')
          ? {}
          : variants && variants.length > 0
          ? game.orderVariants(variants)[0]
          : null,
        ...add,
        index: items.filter((i) => i.group === group).length,
        group,
      })

      window.dataLayer.push({
        event: 'createListing',
        eventProps: {
          category: 'Create Listing',
          action: 'Select Items to Trade',
        },
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
      })
    }

    if (update) {
      const { item, body } = update
      const currItemIdx = newItems.findIndex(
        (i) => i.index === item.index && i.group === item.group
      )
      newItems[currItemIdx] = { ...newItems[currItemIdx], ...body }
    }

    if (replace) {
      const { currencyKey, body } = replace
      newItems = newItems.filter(
        (i) => !(i.group === group && i[currencyKey] !== undefined)
      )
      if (body[currencyKey]) newItems.push(body)
    }

    if (remove) {
      newItems = newItems.filter(
        (i) => !(i.index === remove.index && i.group === remove.group)
      )
    }

    handleChange(
      newItems,
      newItems.find((i) => i.canCatalog) === undefined ? false : true,
      newItems.find((i) => i.canDiy) === undefined ? false : true
    )
    this.setState({ items: newItems })
  }

  abortController = new window.AbortController()

  render = () => {
    const { game } = this.context
    const { items, groups, currAlert, catalogOnly, defaultOptions } = this.state
    let { creating, isAuction, listing, label, noOr, noCurrencies, offer } =
      this.props
    const isCompactView = game.has('LISTINGS:COMPACT')

    const itemGroups = []
    for (let i = 0; i < groups; i++) {
      itemGroups[i] = items.filter((it) => it.group === i && !it.currency)
    }
    if (itemGroups.length === 0) itemGroups[0] = []

    let isCounterOffer = offer !== undefined
    if (offer) listing = offer.listing

    let counterBells,
      counterNMT,
      counterWishlist,
      counterAll = false

    if (isCounterOffer) {
      if (offer.prices) {
        offer.prices.forEach((offer) => {
          if (!counterBells && offer.bells !== null) {
            counterBells = true
          }
          if (!counterNMT && offer.name === 'Nook Miles Ticket') {
            counterNMT = true
          }
          if (
            !counterWishlist &&
            offer.item_id !== null &&
            offer.name !== 'Nook Miles Ticket'
          )
            counterWishlist = true
        })
      } else {
        counterBells = counterNMT = counterWishlist = true
      }
    }

    if (counterBells && counterNMT && counterWishlist) counterAll = true

    let acceptingAll =
      (listing &&
        !listing.offer_bells &&
        !listing.offer_nmt &&
        !listing.offer_wishlist) ||
      (isCounterOffer && counterAll)
    let isBellOffer =
      listing && listing.make_offer
        ? listing.offer_bells || acceptingAll
        : isCounterOffer
        ? counterBells
        : true
    let isNMTOffer =
      listing && listing.make_offer
        ? listing.offer_nmt || acceptingAll
        : isCounterOffer
        ? counterNMT
        : true
    let isWishlistOffer =
      listing && listing.make_offer
        ? listing.offer_wishlist || acceptingAll
        : isCounterOffer
        ? counterWishlist
        : true
    let acceptingProp =
      listing && listing.properties?.find((prop) => prop.type === 'accepting')

    const CustomItemOption = (customStyles) => (props) => {
      return <ItemOption {...props} customStyles={customStyles} />
    }

    return (
      <div>
        <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
        <div>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            {itemGroups.map((group, groupIndex) => {
              if (!group) group = []
              return (
                <div key={groupIndex}>
                  {groupIndex > 0 && (
                    <GroupBreak>
                      <Tooltip
                        text='Delete Group'
                        hoverStyle={{ height: '37px' }}
                      >
                        <button
                          className='btn-icon'
                          onClick={() => this.deleteGroup(groupIndex)}
                          aria-label='Delete Group'
                        >
                          <HiOutlineX style={{ fontSize: 25 }} />
                        </button>
                      </Tooltip>
                      <Trans i18nKey='orGroup' />
                      <span />
                    </GroupBreak>
                  )}
                  <div className='row'>
                    {game.currencies && (
                      <SpecialCurrencies
                        currencies={game.currencies}
                        groupIndex={groupIndex}
                        updateItems={this.updateItems}
                        getItemsDirect={this.getItemsDirect}
                      />
                    )}
                    {(creating ||
                      (isWishlistOffer && listing && !listing.end_time)) &&
                      !isAuction && (
                        <div
                          className={
                            isCompactView
                              ? 'input-row compact col-xs-12'
                              : 'input-row col-xs-12 col-sm-6'
                          }
                        >
                          {!isCompactView && (
                            <div className='input-label'>
                              {label || <Trans i18nKey='itemsYoureOffering' />}
                            </div>
                          )}
                          {listing &&
                            listing.offer_wishlist &&
                            acceptingProp === undefined && (
                              <div style={{ color: 'gray' }}>
                                <Trans i18nKey='wishlistTradeNote' />
                              </div>
                            )}
                          <div
                            className='offer-table-select'
                            id='item-offer-table-select'
                          >
                            <StyledAsyncSelect
                              alt={isCompactView}
                              altColor={isCompactView}
                              cacheOptions={catalogOnly}
                              components={{
                                Option: CustomItemOption({
                                  fontSize: 16,
                                  imgSize: game.ui?.itemSelectSize || '60px',
                                }),
                              }}
                              defaultOptions={
                                defaultOptions?.length > 0
                                  ? defaultOptions
                                  : true
                              }
                              hideSelectedOptions={false}
                              loadOptions={this.getItems}
                              menuPlacement='auto'
                              onChange={(value) => {
                                this.setState({ stepIndex: 1, run: true })
                                this.updateItems({
                                  add: value,
                                  group: groupIndex,
                                })
                              }}
                              placeholder='Search Items...'
                              value=''
                            />
                          </div>
                          {game.has('ITEMS:CATALOG_TAG') && (
                            <Checkbox
                              label='Only show items in my catalog'
                              checked={catalogOnly}
                              onChange={() => {
                                this.setState(
                                  { catalogOnly: !catalogOnly },
                                  () => {
                                    this.getItemsDirect()
                                  }
                                )
                              }}
                            />
                          )}
                        </div>
                      )}
                  </div>
                  <OfferTableGroup
                    creating={creating}
                    game={game}
                    group={group}
                    groupIndex={groupIndex}
                    isBellOffer={isBellOffer}
                    isNMTOffer={isNMTOffer}
                    itemGroups={itemGroups}
                    listing={listing}
                    noCurrencies={noCurrencies}
                    updateItems={this.updateItems}
                  />
                </div>
              )
            })}
          </DragDropContext>
          {!noOr && groups + 1 < 4 && !isAuction && (
            <div style={{ textAlign: 'center' }}>
              <button
                className='and-or-btn'
                onClick={() => {
                  if (groups + 1 >= 4) {
                    this.setState({
                      currAlert: 'No more than four item groups allowed',
                    })
                  } else {
                    this.setState({ items, groups: groups + 1, currAlert: '' })
                  }
                }}
                style={{ margin: 5 }}
                aria-label='Or'
              >
                <Trans i18nKey='orGroup' />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default OfferTable
