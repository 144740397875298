import React, { useContext, useState, useEffect } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'
import { getItems } from '../../services/items'
import { createOffer } from '../../services/offers'

import { ThemeDiv, CancelLink, StyledLink } from '../Styled'
import { Checkbox } from '../inputs'

import ItemIcon from '../Items/ItemIcon'
import Alert from '../Alert'
import { Button } from '../inputs'

const OfferCatalogItem = styled.span<{ selected: boolean }>`
  width: 120px;
  padding: 5px;
  border-radius: 10px;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.primary}` : ''};
  text-align: center;
  cursor: pointer;
`

const MakeCatalogOffer = ({ offering, onMake, listing }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)

  const [items, setItems]: any = useState([])
  const [showHas, setShowHas]: any = useState(true)
  const [currAlert, setAlert]: any = useState('')

  useEffect(() => {
    if (offering) {
      const params: any = {
        user: user.id,
        catalog: user.id,
        tags: true,
        selectedTags: [game.ui?.catalog?.tag],
        catalogMin: 2,
        size: 300,
      }
      if (showHas) {
        params.currUser = listing.seller_id
        params.want = true
      } else {
        params.cataloged = 'true'
      }

      getItems(params).then((res) => {
        if (res.error) return
        setItems(res.items)
      })
    }
  }, [offering, showHas])

  // TODO fix later with some sort of game ui text config
  const itemType = 'stickers' // items.length > 0 ? items[0].type : 'items'

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <>
      {!offering && (
        <button
          id='make-offer'
          onClick={() => {
            onMake(true)
          }}
          aria-label='Make an Offer'
        >
          <Trans i18nKey='makeOffer' />
        </button>
      )}
      {offering && (
        <span>
          <h3 style={{ marginBottom: 0 }}>Choose {itemType} to trade</h3>
          <span style={{ display: 'inline-block', marginBottom: 8 }}>
            Click on {itemType} you want to trade
          </span>
          <Checkbox
            label='Only show stickers they need'
            checked={showHas}
            onChange={() => {
              setShowHas(!showHas)
            }}
          />
          {items.length > 0 ? (
            <>
              <span className='offer-catalog'>
                {items.map((item, idx) => {
                  const catalog = item.catalog ? item.catalog[0] : null
                  return (
                    <OfferCatalogItem
                      onClick={() => {
                        items[idx].selected =
                          items[idx].selected === undefined
                            ? true
                            : !items[idx].selected
                        setItems([...items])
                      }}
                      selected={item.selected}
                      key={idx}
                    >
                      {/* @ts-ignore */}
                      <ItemIcon item={item} size={128}>
                        {catalog?.amount > 1 && (
                          <ThemeDiv className='offer-catalog-amount'>
                            +{catalog.amount - 1}
                          </ThemeDiv>
                        )}
                      </ItemIcon>
                      {item.name}
                    </OfferCatalogItem>
                  )
                })}
              </span>
              <span className='offer-btn-bar'>
                {/* @ts-ignore */}
                <Button
                  onClick={() => {
                    // @ts-ignore
                    return createOffer({
                      game,
                      listing,
                      items: items
                        .filter((item) => item.selected)
                        .map((item, idx) => {
                          return { quantity: 1, item_id: item.id, group: idx }
                        }),
                      // TODO make these fields optional
                      amount: 1,
                      bells: 0,
                      nmt: 0,
                      user,
                    }).then((res) => {
                      if (res.error) return setAlert(res.error)
                      onMake(false)
                    })
                  }}
                >
                  Submit Offer
                </Button>
                <CancelLink
                  onClick={() => onMake(false)}
                  style={{ marginLeft: 10 }}
                >
                  <Trans i18nKey='cancel' />
                </CancelLink>
              </span>
            </>
          ) : (
            <span style={{ display: 'block' }}>
              <span style={{ display: 'block', marginBottom: 16 }}>
                You must have cards in your album in order to make an offer.
                You currently do not have any duplicates in your album. 
                Head to your profile to add some!
              </span>
              <StyledLink
                to={`${routePrefix}/profile/${user.id}/catalog`}
                className='btn'
              >
                Go to Profile
              </StyledLink>
            </span>
          )}
        </span>
      )}
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </>
  )
}

export default MakeCatalogOffer
