import React from 'react'
import { Trans } from 'react-i18next'
import Tutorial from '../../Tutorial'

import {
  getCurrentTutorial,
  hasFinishedTutorial,
} from '../../../services/tutorial'

const Confirm = ({ site, listingProperties, close }) => {
  const currentTutorial = getCurrentTutorial()

  const hasImg = listingProperties
    ? Object.keys(listingProperties).find(
        (propKey) =>
          listingProperties[propKey] &&
          listingProperties[propKey].type === 'image'
      )
    : false
  const isTourRunning =
    currentTutorial &&
    currentTutorial === 'Listing Tutorial' &&
    !hasFinishedTutorial('Listing')

  let tutorialSteps = []
  if (
    document.getElementsByClassName('achievement-popup-container').length > 0
  ) {
    tutorialSteps.push({
      target: '.achievement-popup-container',
      content: `Congratulations on making your first listing! You have received an achievement!`,
      placement: 'top',
      disableBeacon: true,
      spotlightClicks: true,
    })
  }
  tutorialSteps.push({
    target: '#navbar-profile-link',
    content: `Let's go to your profile to view the listing you just made.`,
    placement: 'top',
    disableBeacon: true,
    spotlightClicks: true,
  })

  return (
    <div className='create-listing-confirm'>
      <div className='create-listing-confirm-title'>
        <Trans i18nKey='listingSubmitted' />{' '}
        {hasImg
          ? 'If you submitted a photo with your listing, a mod must approve the photo before your listing can go live.'
          : ''}{' '}
        <Trans i18nKey='thanksForListing' values={{ site: site }} />
      </div>
      <button id='create-listing-confirm' onClick={close} aria-label='OK'>
        OK
      </button>
      <Tutorial steps={tutorialSteps} run={isTourRunning} />
    </div>
  )
}

export default Confirm
