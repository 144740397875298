import { getLocalQuests, trackQuest } from './quests'
import tracker from './tracker'

const envCheck =
  process.env.REACT_APP_CAPTCHA_ENV &&
  process.env.REACT_APP_CAPTCHA_ENV === 'production'

export const skipTutorial = (progressKey, location) => {
  let mixpanelKey = progressKey
  // tracking if user clicks skip and which tutorial they click it on
  try {
    if (envCheck && progressKey) {
      if (
        progressKey === 'offerTutorialSteps' &&
        location.pathname.includes('/offers/made/')
      ) {
        mixpanelKey = 'listingTutorialSteps'
      }
      tracker.track('Clicked Skip Tutorial', {
        tutorial: mixpanelKey,
        from_page: location ? location.pathname : '',
      })
    }
  } catch {}
  if (progressKey) {
    trackQuest(progressKey, undefined, undefined, true, false, 'closed')
  }
}

export const getCurrentTutorial = () => {
  const tutorials = ['Offer Tutorial', 'Listing Tutorial']
  const quest = getLocalQuests().find((quest) => tutorials.includes(quest.name))
  return quest ? quest.name : null
}

// tutorial: Offer or Listing
export const hasFinishedTutorial = (tutorial: string) => {
  const localQuests = getLocalQuests()
  let quest
  if (tutorial) {
    quest = localQuests.find((quest) => quest.name === `${tutorial} Tutorial`)
  } else {
    const tutorials = ['Offer Tutorial', 'Listing Tutorial']
    quest = localQuests.some((quest) => tutorials.includes(quest.name))
  }
  return !quest
}

export const finishTutorial = async (progressKey: string) => {
  // tracking which tutorial finished
  try {
    if (envCheck && progressKey) {
      tracker.track('Finished Tutorial', {
        tutorial: progressKey,
      })
    }
  } catch {}
  await trackQuest(progressKey, undefined, undefined, false, false, 'completed')
}

// TODO: set up tutorial reset
// export const resetTutorial = () => {
//   localStorage.removeItem('tutorialSkipped')
// }

export const handleJoyrideCallback = async (
  data: any,
  last: boolean,
  progressKey: string,
  history: any
) => {
  const { status, step, type } = data
  const currentTutorial = await getCurrentTutorial()

  if (
    type === 'tour:start' &&
    status === 'running' &&
    step &&
    step?.content.includes('get started on your first offer!')
  ) {
    // tracking start of tutorial
    try {
      if (envCheck) {
        tracker.track('Started Offer Tutorial')
      }
    } catch {}
  }

  if (type === 'step:after' && step && step?.data && step?.data?.nextPage) {
    history.push(step.data.nextPage)
  }

  if (status === 'finished' && last) {
    await finishTutorial(progressKey)
    if (step && step?.data && step?.data?.homePage) {
      history.push(step.data.homePage)
    }
    if (progressKey === 'listingTutorialSteps') window.location.reload()
    return false
  }

  if (status === 'skipped' && type === 'tour:end') {
    if (!progressKey) {
      progressKey =
        currentTutorial === 'Listing Tutorial'
          ? 'listingTutorialSteps'
          : 'offerTutorialSteps'
    }
    if (progressKey) skipTutorial(progressKey, history.location)
    return false
  }
}
