import React, { useContext, useEffect, useState } from 'react'
import useScript from 'react-script-hook'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'

import { useTheme } from '../games/controller'
import GameContext from '../GameContext'
import UserContext from '../contexts/UserContext'
import i18n from '../i18n'

import can from '../services/rbac'
import http from '../services/http'
import { languageMap } from '../services/languages'
import { getDetails, setDetails } from '../services/details'
import { setUser } from '../services/users'
import {
  getQuests,
  getLocalQuests,
  giveDailies,
  setLocalQuests,
  trackQuest,
} from '../services/quests'
import Alert from '../components/Alert'

export const getFrontendQuests = async (dailyQuests) => {
  let res
  if (dailyQuests) {
    res = dailyQuests
  } else {
    res = await getQuests({
      user: true,
      completed: false,
      daily: true,
      goals: true,
    })
  }

  if (res.error) return
  const existingQuests = getLocalQuests().filter(
    (q) => q.type !== 'session' && q.type !== 'daily' && q.goals
  )
  const newQuests = res.data.filter((q) => {
    return !existingQuests.some((existingQ) => existingQ.id === q.id)
  })
  // Delete quests in local storage that are completed or not per session
  const quests = [...existingQuests, ...newQuests].filter(
    (q) => q.status !== 'completed' && q.status !== 'closed'
  )

  // Don't update existing quests in local storage that aren't completed
  setLocalQuests(quests)
}

const GetDetails = () => {
  const [currAlert, setAlert] = useState('')
  const { user, setUser: setCUser, loading } = useContext(UserContext)
  const { game } = useContext(GameContext)
  const [toggleTheme] = useTheme()

  const history = useHistory()
  const location = useLocation()
  const query = qs.parse(location.search.substring(1))

  const loggedIn = user !== null && user !== undefined
  const hideAds = can('ADS:HIDE') || (game.has && game.has('ADS:DISABLED'))
  if (hideAds) window.ads = false

  const [loadingAds, error] = useScript({
    src: hideAds
      ? null
      : 'https://dn0qt3r0xannq.cloudfront.net/akrew-yOao3vtSjC/nookazon-longform/prebid-load.js',
  })

  // Refresh User Details
  useEffect(() => {
    if (!loading) {
      if (game.name !== 'Traderie' && loggedIn) {
        const details = getDetails(game.schema)
        if (
          !details ||
          !details.timestamp ||
          (Date.now() - new Date(details.timestamp)) / 1000 > 450
        ) {
          http.get('/users/details').then((res) => {
            if (res.error) return
            setDetails(game.schema, res.details)
            const userWithDetails = { ...user, ...res.details }
            setUser(userWithDetails)
            setCUser(userWithDetails)
          })
        } else {
          const userWithDetails = { ...user, ...details }
          setUser(userWithDetails)
          setCUser(userWithDetails)
        }
      }
    }
  }, [game.name, game.schema, loggedIn, loading])

  const setToken = (fcmToken, game) => {
    if (fcmToken) {
      http
        .put(`/accounts/updateFcm`, {
          token: fcmToken,
          site: game,
        })
        .then((res) => {
          if (res.error) return setAlert(res.error)
          if (
            window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ fcm: 'true' })
            )
          } else {
            setAlert('You have successfully enabled notifications')
          }
        })
    }
  }

  // Query FCM Token
  useEffect(() => {
    if (query.fcmToken) {
      if (loggedIn) {
        setToken(query.fcmToken, query.game || null)
      } else {
        setAlert(
          'There was an error while trying to enable notifications. Please try again.'
        )
      }
    }
  }, [query.fcmToken, query.game, loggedIn])

  // Query change language
  useEffect(() => {
    const lang = query.lang
    if (lang && Object.keys(languageMap).includes(lang)) {
      window.localStorage.setItem('defaultLanguage', lang)
      i18n.changeLanguage(lang)
      history.push({ search: '' })
      window.location.reload()
    }
  }, [query.lang, history])

  // Query change theme
  useEffect(() => {
    if (query.theme) toggleTheme()
  }, [query.theme, toggleTheme])

  // App Change Theme && Quests
  useEffect(() => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      const currentTheme = window.localStorage.getItem('theme')
      if (currentTheme)
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ theme: currentTheme })
        )
    }

    if (loggedIn) {
      // Check if last dailies received were from day before
      let lastDailies = localStorage.getItem('dailies')

      if (
        !lastDailies ||
        DateTime.now()
          .startOf('day')
          .diff(
            DateTime.fromJSDate(new Date(lastDailies)).startOf('day'),
            'hours'
          )
          .as('hours') ||
        DateTime.now()
          .startOf('day')
          .diff(DateTime.fromISO(lastDailies).startOf('day'))
          .as('hours') >= 24
      ) {
        giveDailies().then((res) => {
          localStorage.setItem('dailies', DateTime.now().startOf('day'))
          getFrontendQuests(res)
          // Daily login bonus quest
          trackQuest('loggedIn', undefined, undefined, true, true)
        })
      } else {
        getFrontendQuests()
      }
    }
  }, [loggedIn])

  return (
    <>
      {currAlert !== '' && <Alert onClick={setAlert('')} alert={currAlert} />}
    </>
  )
}

export default GetDetails
