import jwt_decode from 'jwt-decode'

import http from './http'
import { getDetails } from './details'
import { getSchema } from './games'

export const getJWT = () => {
  let token = localStorage.getItem('jwt')
  if (token) return `Bearer ${token}`
  return null
}

export const setJWT = (jwt) => {
  localStorage.setItem('jwt', jwt)
  localStorage.setItem('user', JSON.stringify(jwt_decode(jwt)))
}

export const getUser = (schema) => {
  if (!schema) schema = getSchema()
  try {
    let jwt = localStorage.getItem('jwt')
    let user = localStorage.getItem('user')
    if (!user && jwt) {
      user = JSON.stringify(jwt_decode(jwt))
      setJWT(jwt)
    }
    if (user) {
      const details = getDetails(schema)
      return { ...JSON.parse(user), ...details }
    }
  } catch (err) {
    return null
  }
}

export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const validateUsername = (username) => {
  return /^[\w\d \\.]{3,15}$/.test(username)
}

export const getUsers = (qsParams, signal) => {
  return http.get(`/users`, qsParams, { signal })
}

export const getAccount = (userId, options) => {
  return http.get(`/accounts`, { user: userId }, options)
}

export const goOnline = () => {
  return http.put(`/accounts/update`, {
    status: 'online',
  })
}

export const getCurrentUser = (qsParams, signal) => {
  return http.get(`/user`, qsParams, { signal })
}

export const logOut = () => {
  try {
    localStorage.removeItem('details')
    localStorage.removeItem('user')
    localStorage.removeItem('ggJWT')
    return localStorage.removeItem('jwt')
  } catch (err) {
    return
  }
}

export const logOutAllUsers = () => {
  return http
    .put(`/accounts/update`, {
      last_session_reset_date: new Date(),
    })
    .then(() => logOut())
}

export const getMods = (query) => {
  return http.get('/mods', query).then((res) => {
    if (res.error) return res

    return {
      mods: res.mods.filter(
        (v, i, a) => a.findLastIndex((v2) => v2.username === v.username) === i
      ),
    }
  })
}

export const updateEmail = (email) => {
  return http.put(`/accounts/email`, {
    email: email.trim().toLowerCase(),
  })
}

export const updateUsername = (username) => {
  if (!validateUsername(username))
    return new Promise((resolve) => {
      resolve('Invalid username')
    })
  return http.put(`/accounts/username`, { username })
}

export const checkBio = (text) => {
  return http.post('/users/bio/check', { text })
}
