import React, { useState, useEffect } from 'react'

import { getListingDate, getTimeLeft } from '.'

type Props = {
  isCompactView?: boolean
  offer: any
  listing: { [a: string]: any }
  slider?: boolean
}

const ListingDate = ({ isCompactView, listing, offer, slider }: Props) => {
  const [counter, setCounter] = useState(getTimeLeft(listing.end_time))

  useEffect(() => {
    if (listing.end_time) {
      const timeLeft = getTimeLeft(listing.end_time)
      if (timeLeft !== 'AUCTION OVER') {
        setTimeout(() => setCounter(getTimeLeft(listing.end_time)), 1000)
      } else {
        setCounter(timeLeft)
      }
    }
  }, [listing.end_time, counter])

  return (
    <span
      className={`listing-date${
        isCompactView && !slider ? '-compact' : '-compact-slider'
      }`}
    >
      {!offer
        ? listing.end_time
          ? `${counter}`
          : getListingDate(listing.updated_at)
        : getListingDate(offer.created_at)}
    </span>
  )
}

export default ListingDate
