import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Trans } from 'react-i18next'
import isEmail from 'validator/lib/isEmail'
import qs from 'qs'

import { UserContext } from '../../contexts'
import { setUser, updateEmail, updateUsername } from '../../services/users'

import { StyledErrorMessage } from '../../components/Styled'

const CompleteProfile = ({ history, location }) => {
  const [error, setError] = useState<string>('')
  const { user, setUser: setCUser } = useContext(UserContext)

  const query = qs.parse(location.search.substring(1))
  const needsUsername = query.needsUsername === 'true'

  return (
    <div className='login'>
      {/* <Helmet /> */}
      <div className='container'>
        <h1>Complete your Profile</h1>
        <Formik
          initialValues={{ username: '', email: '' }}
          validate={(values) => {
            const errors: any = {}

            if (needsUsername) {
              if (!values.username)
                errors.username = <Trans i18nKey='required' />
              return errors
            }

            if (!values.email) errors.email = <Trans i18nKey='required' />
            if (values.email && !isEmail(values.email)) {
              errors.email = <Trans i18nKey='emailError' />
            }
            if (
              values.email &&
              (values.email.includes('@gmail.com') ||
                values.email.includes('@googlemail.com') ||
                values.email.includes('@outlook.com'))
            ) {
              if (values.email.includes('+')) {
                errors.email = <Trans i18nKey='emailGmailError' />
              }
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const reroute = localStorage.getItem('loginReroute')

            if (needsUsername) {
              updateUsername(values.username).then((res) => {
                setSubmitting(false)
                if (res.error) return setError(res.error)
                user.username = values.username
                setUser(user)
                setCUser(user)
                history.push(reroute)
              })
            } else {
              updateEmail(values.email).then((res) => {
                setSubmitting(false)
                if (res.error) return setError(res.error)
                //   user.email = values.email
                //   setUser(user.email)
                //   setCUser(user.email)
                history.push(reroute)
              })
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {needsUsername && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='username' />
                  </div>
                  <Field name='username' id='username_input' />
                  <StyledErrorMessage name='username' component='div' />
                  <div className='signup-sublabel'>
                    <Trans i18nKey='completeProfileUsername' />
                  </div>
                </div>
              )}
              {!needsUsername && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='email' />
                  </div>
                  <Field type='email' name='email' id='email_input' />
                  <StyledErrorMessage name='email' component='div' />
                  <div className='signup-sublabel'>
                    <Trans i18nKey='emailMessage' />{' '}
                    <Trans i18nKey='completeProfileMessage' />
                  </div>
                </div>
              )}
              <div className='login-btn-bar'>
                <button type='submit' disabled={isSubmitting} aria-label='Save'>
                  <Trans i18nKey='save' />
                </button>
              </div>
              <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CompleteProfile
