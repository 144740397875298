import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { resizedImg } from '../../services/images'

import Image from '../Image'
import ItemLine from './ItemLine'
import ListingIcon from './ListingItem/ListingIcon'
import Tooltip from '../Tooltip'
import { StyledLink } from '../Styled'

const StyledText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${({ theme }) => theme.btnBackground};
`

const StyledOr = styled.div`
  color: ${({ theme }) => theme.btnBackground};
`

const getBlankPrice = (buying, isOffer) => {
  if (buying) {
    return null
  } else {
    return 'Free'
  }
}

type PriceProps = {
  product: any
  prices: any
  limit?: number
  listing: any
  isCompactView?: any
  isOffer?: any
  isListingItem?: any
  showDetails?: any
  styles?: any
}

const PriceLines = ({
  game,
  isCompactView,
  isOffer,
  listing,
  prices,
  product,
  showDetails,
  styles,
}: any) => {
  const { stock, properties } = listing

  return prices.map((price, idx) => {
    const { bells, name, item_id } = price
    if (isCompactView) {
      return (
        <React.Fragment key={`price${idx}`}>
          {(product
            ? game.currencies[0].img || !game.has('ITEMS:NO_PRODUCT_IMGS')
            : true) && (
            <ListingIcon
              item={price}
              listing={listing}
              listView={true}
              propImgSrc={game.currencies[0].img || ''}
              isCompactView={isCompactView}
              showDetails={showDetails}
            />
          )}
          {prices[idx + 1] && price.group !== prices[idx + 1].group && (
            <StyledOr
              className={`prices-or-compact ${showDetails && 'show-details'}`}
            >
              OR
            </StyledOr>
          )}
        </React.Fragment>
      )
    } else {
      return (
        <div key={idx} className='price-line'>
          {bells && (
            <div className='listing-bells'>
              {game.currencies[0] && (
                <Image
                  src={resizedImg(game.currencies[0].img, 20)}
                  resizedsrc={resizedImg(game.currencies[0].img, 20, true)}
                  errimg={game.currencies[0].img}
                  alt={game.currencies[0].name}
                  className='bells-icon'
                />
              )}
              {bells.toLocaleString()}
              {stock && isOffer && <>&nbsp;(each)</>}
            </div>
          )}
          {name && (
            <>
              <ItemLine item={{ ...price, id: item_id }} style={styles?.line} />
              {stock && isOffer && <>&nbsp;(each)</>}
            </>
          )}
          {prices[idx + 1] && price.group !== prices[idx + 1].group && (
            <div style={{ fontWeight: 'bold' }}>&nbsp; OR</div>
          )}
        </div>
      )
    }
  })
}

const Prices = ({
  product,
  prices,
  limit,
  listing,
  isCompactView,
  isOffer,
  isListingItem,
  showDetails,
  styles,
}: PriceProps) => {
  const context = useContext(GameContext)
  const { t } = useTranslation()
  let { game, routePrefix } = context
  let {
    id,
    seller,
    seller_id,
    selling,
    make_offer,
    touch_trading,
    need_materials,
    offer_bells,
    offer_nmt,
    offer_wishlist,
    offer_wishlist_id,
    properties,
    wishlist,
  } = listing || {}
  if (prices) prices.sort((a, b) => a.group - b.group)
  const wishlistIcon = game.ui?.wishlistIcon || 'nookazon/icons/present.png'

  const isHide = isOffer ? false : limit ? prices?.length > limit : false

  // combine price props 
  let priceProperties = []
  const typeProps = properties?.filter((prop) => prop.type === 'accepting' && prop.property === 'Types')
  if (typeProps?.length > 0 && priceProperties.find((priceProp: any) => priceProp.property === 'Types') === undefined) {
    const newTypeProps = JSON.parse(JSON.stringify(typeProps[0]))
    const typeStrings = typeProps.map((typeProp) => t(typeProp.string)).sort().join(', ')
    newTypeProps.string = typeStrings
    // @ts-ignore
    priceProperties.push(newTypeProps)
  }

  return (
    <div className={`${isCompactView ? 'prices-row' : ''}`}>
      {touch_trading && (
        <div>
          <img
            src='https://cdn.nookazon.com/nookazon/icons/touch.png'
            alt='offer'
            className='listing-img'
          />
          <Tooltip
            text='Adding items to your catalog by picking them up and dropping them.'
            width={200}
          >
            <Trans i18nKey='touchTrade' />
          </Tooltip>
        </div>
      )}
      {need_materials && (
        <div>
          <img
            src='https://cdn.nookazon.com/nookazon/icons/build.png'
            alt='materials'
            className='listing-img'
          />
          <Trans i18nKey='materialsRequired' />
        </div>
      )}
      {make_offer && !isOffer && (
        <div>
          {isCompactView ? (
            <StyledLink
              to={`${routePrefix}/listing/${id}`}
              aria-label='Make an Offer'
            >
              <ListingIcon
                isMakeOffer
                item={{ id }}
                listing={listing}
                listView={true}
                propImgSrc={game.currencies[0].img || ''}
                isCompactView={isCompactView}
                showDetails={showDetails}
              />
            </StyledLink>
          ) : (
            <StyledLink
              to={`${routePrefix}/listing/${id}`}
              aria-label='Make an Offer'
            >
              <Image
                src={resizedImg(game.icons.loading, 20)}
                resizedsrc={resizedImg(game.icons.loading, 20, true)}
                errimg={game.icons.loading}
                alt='offer'
                className='listing-img'
              />
              <Trans i18nKey='makeOffer' />
            </StyledLink>
          )}
          {(offer_bells || offer_nmt || offer_wishlist || (priceProperties?.length > 0 && game.has('PROPERTIES:ACCEPTING'))) && (
            <div style={isCompactView ? { maxWidth: '100px' } : {}}>
              <Trans i18nKey='accepting' />
              &nbsp;
              {priceProperties?.map((priceProperty: any) => {
                return (
                  <span>
                    {priceProperty.string}&nbsp;
                  </span>
                )
              })}
              {priceProperties?.length > 0 && game.has('PROPERTIES:ACCEPTING') && (offer_bells || offer_nmt || offer_wishlist) &&
                <span>&nbsp;or</span>
              }
              {offer_bells && game.currencies[0] && (
                <Image
                  src={resizedImg(game.currencies[0].img, 20)}
                  resizedsrc={resizedImg(game.currencies[0].img, 20, true)}
                  errimg={game.currencies[0].img}
                  alt={game.currencies[0].name}
                  className='listing-img'
                />
              )}
              {offer_bells && offer_nmt && <span>or</span>}
              {offer_nmt && game.currencies[1] && (
                <Image
                  src={resizedImg(game.currencies[1].img, 20)}
                  resizedsrc={resizedImg(game.currencies[1].img, 20, true)}
                  errimg={game.currencies[1].img}
                  alt={game.currencies[1].name}
                  className='listing-img'
                />
              )}
              {((offer_bells && offer_wishlist) ||
                (offer_nmt && offer_wishlist)) && <span>or</span>}
              {offer_wishlist && (
                <StyledLink
                  to={
                    offer_wishlist_id === null
                      ? `${routePrefix}/profile/${
                          seller ? seller.id : seller_id
                        }/wishlist`
                      : `${routePrefix}/profile/${
                          seller ? seller.id : seller_id
                        }/wishlist/${offer_wishlist_id}`
                  }
                  aria-label='Wishlist Items'
                >
                  <Image
                    src={resizedImg(
                      `https://cdn.nookazon.com/${wishlistIcon}`,
                      20
                    )}
                    resizedsrc={`http://cdn.nookazon.com.s3-website-us-east-1.amazonaws.com/20x20/${wishlistIcon}`}
                    errimg={`https://cdn.nookazon.com/${wishlistIcon}`}
                    alt='offer'
                    className='listing-img'
                  />
                  <Trans i18nKey='wishlistItems' />
                  {wishlist && wishlist.name && (
                    <span> from {wishlist.name}</span>
                  )}
                </StyledLink>
              )}
            </div>
          )}
        </div>
      )}
      {!prices && !make_offer && <div>{getBlankPrice(!selling, isOffer)}</div>}
      {prices && (!make_offer || isOffer) && (
        <div className={`${isCompactView ? 'prices-row' : ''}`}>
          {isListingItem && !isCompactView && (
            <StyledText className='trading-for'>
              <Trans i18nKey={selling ? 'tradingFor' : 'offering'} />
            </StyledText>
          )}
          <PriceLines
            game={game}
            isCompactView={isCompactView}
            isOffer={isOffer}
            listing={listing}
            prices={isHide ? prices.slice(0, limit) : prices}
            product={product}
            styles={styles}
            showDetails={showDetails}
          />
          {isHide && (
            <Tooltip
              text={
                <div>
                  <PriceLines
                    game={game}
                    isCompactView={isCompactView}
                    isOffer={isOffer}
                    listing={listing}
                    prices={prices.slice(limit)}
                    product={product}
                    showDetails={showDetails}
                    styles={{
                      line: { color: 'white' },
                    }}
                  />
                </div>
              }
            >
              <span>
                ... {prices.length - (limit || 0)} <Trans i18nKey='more' />
              </span>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default Prices
