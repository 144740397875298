import React, { useContext } from 'react'
import styled from 'styled-components'
import { FaInfinity } from 'react-icons/fa'

import GameContext from '../../GameContext'

import Image from '../Image'
import VariantSelect from './VariantSelect'
import { PermSticker } from './ItemIcon'

const IconContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ isCompactView, theme }) =>
    isCompactView ? theme.border : 'none'};
  padding: ${({ isCompactView, itemExists }) =>
    isCompactView && itemExists ? '8px' : '0px'};
  min-height: ${({ isCompactView, isMobileCompact, itemExists }) =>
    !itemExists
      ? '0px'
      : isMobileCompact
      ? '41px'
      : isCompactView
      ? '83px'
      : '0px'};
  min-width: ${({ isCompactView, isMobileCompact, itemExists }) =>
    !itemExists
      ? '0px'
      : isMobileCompact
      ? '41px'
      : isCompactView
      ? '83px'
      : '0px'};
`

const StyledLabel = styled.div`
  text-align: ${({ isCompactView }) => (isCompactView ? 'center' : 'left')};
  line-height: 16px;
  max-width: ${({ isCompactView, itemExists }) =>
    itemExists && isCompactView ? '83px' : 'none'};
`

const isMobile = window.innerWidth <= 600

const ItemRow = ({ item, onVariantChange, activeProperties, styles }) => {
  const { game } = useContext(GameContext)
  const isCompactView = game.has('LISTINGS:COMPACT')
  const isMobileCompact = isCompactView && isMobile
  const isPermanent =
    activeProperties &&
    Object.keys(activeProperties).find((k) => {
      const prop = activeProperties[k]
      if (prop?.property === 'Permanent' && prop?.option) return true
      return false
    })

  return (
    <div className='item-row-container'>
      <IconContainer
        className={`item-with-image${isCompactView ? '-compact' : ''}`}
        isCompactView={isCompactView}
        isMobileCompact={isMobileCompact}
        itemExists={item}
      >
        {(item.img_url || item.img) && (
          <Image
            className={`offer-icon${isCompactView ? '-compact' : ''}`}
            src={
              item.variant && item.variant.name
                ? item.variant.img
                : item.img_url
                ? item.img_url
                : item.img
            }
            style={styles?.item}
            alt='brand-img'
          />
        )}
        {isPermanent && (
          <PermSticker>
            <FaInfinity />
          </PermSticker>
        )}
        {game.has('LISTINGS:DIY') && item.diy && (
          <img
            src='https://cdn.nookazon.com/nookazon/icons/recipe.png'
            alt='category'
            className='product-diy-icon-offer'
          />
        )}
        <div style={{ flex: 1 }}>
          <StyledLabel
            className='offer-table-label'
            isCompactView={isCompactView}
            isMobileCompact={isMobileCompact}
            itemExists={item}
          >
            {isPermanent && 'Permanent '}
            {item.label || item.name}
          </StyledLabel>
        </div>
      </IconContainer>
      {!item.diy && item.variant && item.variants && (
        <VariantSelect
          variants={item.variants.filter((v) => v.active)}
          variant={item.variant}
          onChange={onVariantChange}
          mobile
          style={{ maxWidth: 300, marginBottom: 0 }}
          size={50}
        />
      )}
    </div>
  )
}

export default ItemRow
