import React from 'react'
import {
  FaClone,
  FaSteam,
  FaPlaystation,
  FaXbox,
  FaBattleNet,
  FaDice,
} from 'react-icons/fa'
import { SiEpicgames, SiRoblox } from 'react-icons/si'
import { Trans } from 'react-i18next'

import NookIcons from '../games/nookazon/icons'

const { CharacterIcon, IslandIcon } = NookIcons

const contacts = {
  friend_code: {
    label: 'Nintendo Switch Friend Code',
    icon: 'https://cdn.nookazon.com/icons/switch.svg',
    regex: '^\\d{4}-\\d{4}-\\d{4}$',
    err: <Trans i18nKey='friendCodeError' />,
  },
  xbox: {
    label: 'XBOX Gamertag',
    icon: ({ style }) => <FaXbox style={{ color: '#117b11', ...style }} />,
    regex: '^.{2,15}$',
  },
  psn: {
    label: 'PSN Online ID',
    icon: ({ style }) => (
      <FaPlaystation style={{ color: '#006cca', ...style }} />
    ),
    regex: '^[a-zA-Z]{1}(\\w|-){2,15}$',
  },
  steam: {
    label: 'Steam Friend Code',
    icon: FaSteam,
    regex: '^\\d{6,9}$',
  },
  epic: {
    label: 'Epic Display Name',
    icon: SiEpicgames,
    regex: '^(\\w|\\.|-){3,16}$',
  },
  bnet: {
    label: 'BNet Tag',
    icon: ({ style }) => <FaBattleNet style={{ color: '#4ae0ff', ...style }} />,
  },
  island_name: {
    label: 'Island Name',
    icon: IslandIcon,
    regex: '^(.){1,10}$',
  },
  villager_name: {
    label: 'Villager Name',
    icon: CharacterIcon,
    regex: '^(.){1,10}$',
  },
  roblox_username: {
    label: 'Roblox Username',
    icon: ({ style }) => <SiRoblox style={{ ...style }} />,
  },
  monopoly: {
    label: 'Monopoly GO! Friend Code',
    icon: ({ style }) => <FaDice style={{ fontSize: 25, ...style }} />,
    regex: '^MGO[A-Z\\d]{9}$',
    err: 'Please enter your Monopoly GO! Friend Code with the MGO like MGOXXXXXXXXX',
  },
  tcg_id: {
    label: 'Pokemon TCG Friend ID',
    regex: '^[\\d]{16}$',
    icon: 'https://cdn.nookazon.com/pokemonscarletviolet/icons/loading.png',
    err: 'Please enter your Pokemon TCG Friend ID without dashes like XXXXXXXXXXXXXXXX',
  },
  info_1: {
    label: 'Creator Code',
    icon: 'https://cdn.nookazon.com/nookazon/icons/creator_code.png',
    regex: '^\\d{4}-\\d{4}-\\d{4}$',
    err: 'Please enter your Creator Code without the MA like XXXX-XXXX-XXXX',
  },
  info_2: {
    label: 'Dream Address',
    icon: 'https://cdn.nookazon.com/nookazon/icons/dream_code.png',
    regex: '^\\d{4}-\\d{4}-\\d{4}$',
    err: 'Please enter your Dream Address without the DA like XXXX-XXXX-XXXX',
  },
  info_3: {
    label: 'Happy Home Network ID',
    icon: 'https://cdn.nookazon.com/nookazon/icons/hhn_code.png',
    regex: '^\\d{4}-\\d{4}-\\d{4}$',
    err: 'Please enter your Happy Home Network ID without the RA like XXXX-XXXX-XXXX',
  },
}

export default contacts
